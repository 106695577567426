import { LocaleMessageObject } from "vue-i18n";

const bg: LocaleMessageObject = {
  avatar: "Борил Колев",
  buttons: "Бутони",
  calendar: "Календар",
  charts: "Чартове",
  components: "Компоненти",
  dashboard: "Начало",
  dtables: "Таблици",
  eforms: "Форми",
  error: "Грешка в страницата",
  etables: "Таблици",
  example: "Пример",
  forms: "Форми",
  fullscreen: "Карта на цял екран",
  google: "Google Maps",
  grid: "Грид система",
  icons: "Икони",
  lock: "Заключен профил",
  login: "Влез в системата",
  maps: "Карти",
  multi: "Мултилевъл меню",
  notifications: "Известия",
  pages: "Страници",
  "my-profile": "Моя профил",
  "edit-profile": "Редакция на профил",
  search: "Търси",
  settings: "Настройки",
  tables: "Таблици",
  tabs: "Табове",
  user: "Потрбителски профил",
  gis: "Aquagis",
  gis_short: "G",
  "sign-in": "Вход",
  username: "Потребителско име",
  password: "Парола",
  "add-point": "Добави сметище",
  "point-details": "Детайли за сметище",
  description: "Описание",
  save: "Запази",
  latitude: "Географска ширина",
  longitude: "Геоографска дължина",
  coordinates: "Координати",
  "registered-on": "Дата на регистрация",
  close: "Затвори",
  "table-data-of-waste-points": "Табличен изглед на сметищата",
  created: "Дата на създаване",
  remarks: "Бележки",
  users: "Потребители",
  "full-name": "Имена",
  role: "Роля",
  phone: "Телефон",
  email: "E-mail",
  "add-user": "Добавяне на потребител",
  "user-details": "Детайли за потребител",
  "first-name": "Име",
  "middle-name": "Презиме",
  "last-name": "Фамилия",
  roles: "Роли",
  "delete-user": "Деактивирай потребителя",
  deactivate: "Деактивирай",
  reports: "Справки",
  choose_report: "Избери справка",

  /* API MESSAGES */
  "Incorrect-username-password": "Грешно потребителско име или парола",
  "NOT-AUTHORIZED":
    "Не сте разпознат от системата. Моля първо влезте с Вашият профил.",

  /* MENU */
  "valves": "Управление на мрежата",

  /* TABLES */
  "$vuetify.noDataText": "Няма намерени резултати",
  "$vuetify.dataFooter.itemsPerPageText": "Записи на страница:",
  "$vuetify.dataFooter.itemsPerPageAll": "Всички",
  "$vuetify.dataFooter.nextPage": "Следваща страница",
  "$vuetify.dataFooter.prevPage": "Предишна страница",
  "$vuetify.dataFooter.firstPage": "Първа страница",
  "$vuetify.dataFooter.lastPage": "Последна страница",
  "$vuetify.dataFooter.pageText": "{0}-{1} от {2}",
  "$vuetify.dataTable.itemsPerPageText": "Записи на страница:",
  "$vuetify.dataTable.ariaLabel.sortDescending": "Низходящо сортиране.",
  "$vuetify.dataTable.ariaLabel.sortAscending": "Възходящо сортиране.",
  "$vuetify.dataTable.ariaLabel.sortNone": "Без сортировка.",
  "$vuetify.dataTable.ariaLabel.activateNone": "Премахни сортировката.",
  "$vuetify.dataTable.ariaLabel.activateDescending":
    "Активирай низходящо сортиране.",
  "$vuetify.dataTable.ariaLabel.activateAscending":
    "Активирай възходящо сортиране.",
  "$vuetify.pagination.ariaLabel.previous": "Предна страница",
  "$vuetify.pagination.ariaLabel.next": "Следваща страница",
  "$vuetify.pagination.ariaLabel.page": "Страница",
  "$vuetify.pagination.ariaLabel.currentPage": "Текуща страница",
  "$vuetify.dataIterator.loadingText": "Моля изчакайте...",
  "$vuetify.pagination.ariaLabel.wrapper": "Страници",
  "$vuetify.dataIterator.noResultsText": "Няма намерени резултати",
  "$vuetify.dataTable.sortBy": "Сортиране по",

  /* Project content */
  project_header_content:
    "Проект CB005.2.12.075: “Ревитализация на изоставени сметища”",
  project_footer_content: `Проектът е съфинансиран от Европейския съюз чрез Програмата Интеррег-ИПП за трансгранично сътрудничество България - Турция.<br />
  Този уебсайт е разработен и се подържа с подкрепата на Европейския съюз, чрез Програма за трансгранично сътрудничество Интеррег-ИПП България-Турция 2014-2020, CCI No 2014TC16I5CB005. Съдържанието на този уебсайт е отговорност единствено на Център за образование и научно сътрудничество "Максима" и по никакъв начин не трябва да се възприема като израз на становището на Европейския съюз или на Управляващия орган и Националния орган на Програмата.`,
  system_and_objects: "Система и обекти",
  external_layers: "Външни слоеве",
  others: "Други",
  hide_all_symbols: "Скрий всички символи",
  show_all_symbols: "Покажи всички символи",
  clear_selection: "Изчисти селекцията",
  filters: "Филтри",
  colors: "Цветове",
  select_attr_for_thematic_coloring:
    "Селектирайте атрибут за тематично оцветяване",
  object_labels: "Етикети на обекти",
  attribute: "Атрибут",
  line_thicknesses_by_diameter: "Дебелина на линията по диаметър",
  specify_from: "Посочете от",
  to: "До",
  thickness: "Дебелина",
  pixels: "Пиксели",
  wms_bkgr: "WMS подложка",
  main_map: "Главна карта",
  system: "Система",
  styles: "Стилове",
  layers: "Слоеве",
  prepared_by: "Изготвен от",
  contractor: "Възложител",
  data: "Дата",
  object: "Обект",
  scale: "Мащаб",
  cancel: "Откажи",
  full_network: "Пълна мрежа",
  export: "Експорт",
  selection: "Селекция",
  version: "Версия",
  clear_all_filters: "Изчисти всички филтри",
  select_or_calcel_group: "Селектирай/Откажи група",
  show_from: "Покажи от",
  invalid_number: "Невалиден номер",
  reset_settings: "Възстанови настройките",
  go_to_id: "Към iD",
  go_to_search: "Към търсене",
  go_to_selection: "Към селекция",
  go_to_superset: "Към суперсет",
  select_one_type_of_objects:
    "Моля, селектирайте само един тип обект - линеен или точков",
  attributes_of: "Атрибути на",
  name: "Име",
  no_results_found: "Няма намерени резултати",
  type: "Тип",
  creation_date: "Дата на въвеждане",
  start_date: "Начален момент на прекъсване",
  end_time_expected: "Планирано възстановяване",
  end_time_real: "Дата и час на реално възстановяване",
  last_edit: "Последна корекция на зона",
  created_by: "Създадено от",
  active_zone:"Активна",
  inactive_zone:"Неактивна",
  zone_polygon: "Полигон",
  section_under_construction: "Участък в ремонт",
  completed_section: "Завършен участък",
  area_with_disrupted_water_supply: "Зона с нарушено водоподаване",
  zone_line: "Линия",
  zone_point: "Точка",
  water: "Вода",
  sewer: "Канал",
  measuring: "Измерване",
  line: "Линия",
  delete: "Изтриване",
  drawing: "Чертане",
  attributes: "Атрибути",
  cancel_changes: "Откажи промените",
  cancel_measuring: "Откажи измерването",
  legend: "Легенда",
  inc_number: "Инк. №",
  data_exit_data: "Данни / Изходни данни",
  date: "Дата",
  linear: "Линейни",
  points: "Точкови",
  areal_objects: "Площни",
  electric_network: "Електроразпределителна мрежа",
  gas_distribution_network: "Газопреносна мрежа",
  communication_network: "Комуникационна мрежа",
  loggers: "Логери",
  detailed_points: "Подробни точки",
  signal_cables: "Сигнални кабели",
  power_cables: "Силови кабели",
  fence: "Ограда",
  other_lines: "Други линейни обекти",
  other_points: "Други точкови обекти",
  developer_tools: "Допълнителни инструменти",
  for_specific_scale: "За желан мащаб въведете нова стойност (Пример 1:500)",
  total_length: "Обща дължина: ",
  unit_of_measure_km: "км",
  unit_of_measure_m: "метра",
  filter_by: "Филтър по",
  map_center: "Център",
  map_scale: "Мащаб",
  show_all_zones: "Покажи всички проектни обекти",
  hide_all_zones: "Скрий всички проектни обекти",
  analize: "Мрежови анализ",
  cancel_analize: "Излез от режим 'Мрежови анализ'",
  search_by_attr: "Търсене по атрибути",
  water_meters_residential: "Битови клиенти",
  water_meters_public: "Обществени клиенти",
  unit_of_measure_m2: "кв. метра",
  breakdown_title:"Аварии",
  search_value: "Стойност",
  search_cadastre: "Търсене по имот",
  coordinate_system: "Координатна система",
  change_breakdown_coordinates:"Смяна на координати",
  connect_breakdown_to_polygon:"Свързване на авария с полигон",
  edit_breakdown_attributes:"Промяна на атрибути",
  go_to_breakdown_location:"Показване на авария",
  confirm:"Потвърдете",
  material:"материал:",
  diameter:"диаметър:",
  move_to_address: "Прмести на Адрес",
remove_marker: "Премахване на маркер",
place_marker: "Поставяне на маркер",
send: "Изпрати ",
selection_polygone_mode:"Режим Избиране На Полигон Към Авария ",
disconnect_breakdown_from_polygon:"Изтрий всички връзки авария/полигон"

};

export default bg;
