import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { IRequestData } from '@/interfaces'
import vue from "@/main";
import qs from "qs";
import reprojectBoundingBox from "reproject-bbox/pluggable.js";
import proj4 from 'proj4';

// create an axios instance
const request: AxiosInstance = axios.create({
  timeout: 550000, // request timeout
  paramsSerializer: params => {
    return qs.stringify(params, { encode: false })
  }
})

// axios request interceptor
request.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = store.getters.token || getToken();
  if (store.getters.token || getToken()) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
},
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)


// axios response interceptor
request.interceptors.response.use((response) => {
  const res: IRequestData = response.data;
  // if the code is different from 200,201,204 show error message.
  if (response.status !== 200 && response.status !== 201 && response.status !== 204 || res.STATUS && res.STATUS != "SUCCESS") {
    vue.$snotify.error(vue.$t(res.MESSAGE) as string);
    return Promise.reject(new Error(res.MESSAGE || 'Error'));
  } else {
    return response;
  }
},
  (error: AxiosError) => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode == 401) {
      store.dispatch("user/logout");
      window.location.href = "/"
    }
    return Promise.reject(error)
  }
)

// fetch request and response interceptor
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const resource = args[0];
  let config = args[1];
  let req = new Request(resource);
  let isStandart = true;

  if (resource['url'] && resource['url'].includes('cadastre')) {

    // Set fetch headers
    config = {
      headers: {
        'Authorization': `Bearer ${store.getters.token}`,
      },
    };
    isStandart = false;

    // Get 3857 coordinates from the url with regex
    const regex = /(BBOX=){1}(?<cords>(\d+.\d+),?(\d+.\d+),?(\d+.\d+),?(\d+.\d+),?)/gm;
    const match = regex.exec(resource['url'])?.groups?.["cords"];
    const bbox3857: number[] = [];
    match?.split(',').forEach(c => { bbox3857.push(Number(c)); });

    // Transform coordinate systems
    const bbox4326 = reprojectBoundingBox({
      bbox: bbox3857,
      reproject: proj4("EPSG:3857", "EPSG:4326").forward,
    });

    // Build targeted url as parameter and encode special chars
    const paramURL = encodeURIComponent(resource['url'].replace(regex, `BBOX=${bbox4326[1]},${bbox4326[0]},${bbox4326[3]},${bbox4326[2]}`));

    // Build new request
    req = new Request(`${process.env.VUE_APP_API_BACKEND}Proxy/GetCadastreTiles?uri=${paramURL}`);
  }

  const response = await originalFetch(isStandart ? resource : req, config);

  if (!response.ok && response.status === 404) {
    // 404 error handling
    return Promise.reject(response);
  }

  return response;
};

export default request;