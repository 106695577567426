
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters([]),
  },
})
export default class SidebarMain extends Vue {
  public toggleIcon: string;
  public panel: number[];
  public showByConfig: boolean;
  public showBreakDownSystem: boolean;

  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];
  @Prop() readonly objlist: { id: number; show: boolean; name: string }[];
  @Prop() readonly externalSystems: {
    id: number;
    show: boolean;
    name: string;
  }[];
  @Prop() readonly otherSystems: { id: number; show: boolean; name: string }[];
  @Prop() readonly selectedElement: any[];
  @Prop() readonly breakDownSystems: { id: number; show: boolean; name: string }[];
  constructor() {
    super();

    this.toggleIcon = "mdi-graph-outline";
    this.panel = [0];
    this.showByConfig =
      Number(process.env.VUE_APP_SHOW_INFO_LAYERS) === 1 ? true : false;
    this.showBreakDownSystem =
      Number(process.env.VUE_APP_SHOW_BREAKDOWN_LAYERS) === 1 ? true : false;
  }

  
 
  public toggleLayers() {
    this.$emit("toggle-layers");
  }

  public toggleExternalLayers() {
    this.$emit("toggle-external-layers");
  }

  public toggleOtherLayers() {
    this.$emit("toggle-other-layers");
  }

  public hideAllSymbolLayers() {
    this.$emit("hide-symbol-layers");
  }

  public showAllSymbolLayers() {
    this.$emit("show-symbol-layers");
  }

  public hideAllDrawnObject() {
    this.$emit("hide-drawn-layers");
  }

  public showAllDrawnObject() {
    this.$emit("show-drawn-layers");
  }

  public clearAllSelected() {
    this.$emit("clear-selected");
  }
  public toggleBreakDownLayers() {
    this.$emit("toggle-breakdown-layers");
  }

  public toggleSidebar() {
    const elem = document.getElementById("sidebar-main");
    const analyticsToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-analytics-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    const layersToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-layer-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    
    const brakedownToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-breakdown-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    const breakdownMarkerToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-breakdown-maneger-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );

    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          analyticsToggle[0].style.display = "none";
          layersToggle[0].style.display = "none";
          brakedownToggle[0].style.display = "none";
          breakdownMarkerToggle[0].style.display = "none";
          this.toggleIcon = "mdi-arrow-collapse-right";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          analyticsToggle[0].style.display = "flex";
          layersToggle[0].style.display = "flex";
          brakedownToggle[0].style.display = "flex";
          breakdownMarkerToggle[0].style.display = "flex";
          this.toggleIcon = "mdi-graph-outline";
        }, 550);
      }
      elem.className = classes.join(" ");
    }
  }
}
