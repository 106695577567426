/* eslint-disable vue/valid-template-root */
<template>
  <div>
    <template v-if="item.children">
      <template v-for="(menu, idx) in item.children" >
        <v-list-item
          :key="`path-${idx}`"
          :href="getSpecialPaths(menu)"
          :rel="menu.path && menu.path !== '#' ? 'noopener' : undefined"
          :to="menu.target == true ? undefined : menu.path"
          :target="menu.target == true ? menu.path : undefined"
          :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
        >
          <v-list-item-icon v-if="menu.meta && menu.meta.icon">
            <s-v-g-loader :icon="menu.meta.icon"></s-v-g-loader>
          </v-list-item-icon>

          <v-list-item-content v-if="menu.name || menu.subtitle">
            <v-list-item-title v-text="$t(menu.name)" />

            <v-list-item-subtitle v-text="$t(menu.subtitle)" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
    <template v-else>
      <v-list-item
        :href="href"
        :rel="href && href !== '#' ? 'noopener' : undefined"
        :target="href && href !== '#' ? '_blank' : undefined"
        :to="item.to"
        :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
      >
        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="computedText"
        />

        <v-list-item-icon v-else-if="item.icon">
          <v-icon v-text="item.icon" />
        </v-list-item-icon>

        <v-list-item-content v-if="item.title || item.subtitle">
          <v-list-item-title v-text="item.title" />

          <v-list-item-subtitle v-text="item.subtitle" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'
  import { mapGetters } from 'vuex';
  import { SVGLoader } from "@/components/";
  import { replaceSpecialKeysInURL } from "@/utils/";

  export default {
    name: 'Item',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          name: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      SVGLoader
    },

    computed: {
      ...mapGetters(['last_map_center', 'last_map_zoom']),
      computedText () {
        if (!this.item || !this.item.name) return ''

        let text = ''

        this.item.name.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },
    },
    methods: {
      getSpecialPaths(menu){
        const urlKeys = [':zoom:', ':lat:', ':lng:'];
        const urlValues = [this.$store.getters.last_map_zoom, this.$store.getters.last_map_center.lat, this.$store.getters.last_map_center.lng]
        return replaceSpecialKeysInURL(menu.path, urlKeys, urlValues);
      }
    }
  }
</script>
