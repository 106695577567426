
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters(["isLogged", "last_map_center", "last_map_zoom"]),
  },
})
export default class SidebarLayers extends Vue {
  public toggleIcon: string;
  public selectedWMSLayers: string[];
  public panel: number[];

  @Prop() readonly wsmlayers: object[];

  constructor() {
    super();

    this.selectedWMSLayers = ["OSM"];
    this.toggleIcon = "mdi-layers-outline";
    this.panel = [0];
  }

  toggleWMSLayers() {
    this.$emit("toggle-wms-layer", this.selectedWMSLayers);
  }

  public toggleSidebar() {
    const elem = document.getElementById("sidebar-layer");
    const breakdownMarkerToggle = Array.from(
        document.getElementsByClassName(
          "sidebar-breakdown-maneger-toggle"
        ) as HTMLCollectionOf<HTMLElement>
      );

    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          breakdownMarkerToggle[0].style.display = "none";
          this.toggleIcon = "mdi-arrow-collapse-right";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          breakdownMarkerToggle[0].style.display = "flex";
          this.toggleIcon = "mdi-layers-outline";
        }, 550);
      }
      elem.className = classes.join(" ");
    }
  }
}
