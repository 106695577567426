/* eslint-disable */
import request from '@/utils/request';

// export function getPolygons() {
//     return request({
//         url: process.env.VUE_APP_API_BACKEND + 'Warehouse/GetPolygons',
//         method: 'get',
//         params: {
//             projectID: process.env.VUE_APP_PROJECTID
//         }
//     }) as any
// }
export function getPolygons(active?: boolean) {
    let params = {};
    
    if (active !== undefined && active !== null) {
        params = { active };
    }
    return request({
        url: process.env.VUE_APP_PROBLEM_REPORTING + 'Regions/GetPolygons',
        method: 'get',
        params: params
    }) as any;
}
export function savePolygon(data) {
    return request({
        url: process.env.VUE_APP_API_BACKEND + 'Warehouse/UpdatePolygonsData',
        method: 'post',
        data: data
    }) as any
}

