
/* eslint-disable */

//Services
import SymbolLayers from "../services/SymbolLayers";
import GeocodeAPI from "../services/GeocodeAPI";
import ActiveDrawingService from "../services/ActiveDrawingService";
import MapService from "../services/MapService";

//Map libraries
import "@/external/print-lib/css/styles.css";
import mapboxgl, { Map, GeoJSONSource, LngLatLike } from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxGeocoder from "@maplibre/maplibre-gl-geocoder";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

//Interfaces
import { IDataObject } from "@/interfaces";

//Components
import SidebarMain from "@/components/map/SidebarMain.vue";
import SidebarAnalytics from "@/components/map/SidebarAnalytics.vue";
import SidebarLayers from "@/components/map/SidebarLayers.vue";
import SidebarBreakdown from "@/components/map/SidebarBreakdown.vue";
import SidebarBreakdownManeger from "@/components/map/SidebarBreakdownManeger.vue";

import ExportAttributes from "@/components/map/ExportAttributes.vue";
import MapBoxCustomPopup from "@/components/map/MapBoxCustomPopup.vue";

//API, Vue, Vuex, Constants
import {
  getMapWMSLayers,
  searchByPropertyValue,
  getValveObjects,
} from "@/api/map";
import { getPolygons, savePolygon } from "@/api/breakdown";
import {
  getTagRefByLang,
  getSystems,
  getObjects,
  getPositions,
  getConditions,
  getOperationals,
} from "@/api/nomenclatures";
import { getMapSettings } from "@/api/definition";
import { getAnalizeByPosition } from "@/api/analytics";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import layer from "@/utils/constants/map/layer";
import tools from "@/utils/constants/map/tools";
import source from "@/utils/constants/map/source";
import common from "@/utils/constants/map/common";
import {
  calcScale,
  calcZoom,
  loadingShow,
  loadingHide,
  getParameter,
} from "@/utils";
import ChangeBreakdownLocationPopup from "./BreakdownDialogs/ChangeBreakdownLocationPopup.vue";

@Component({
  components: {
    SidebarMain,
    SidebarAnalytics,
    SidebarLayers,
    SidebarBreakdown,
    ExportAttributes,
    SidebarBreakdownManeger,
    ChangeBreakdownLocationPopup
  },
  computed: {
    ...mapGetters([
      "last_map_center",
      "last_map_zoom",
      "zone_target_attribute",
      "search_feature",
      "search_cadastre",
      "isMoblie",
      "predefinedBreakdownLayers"
    ]),
  },
})
export default class VMap extends Vue {
  public data: IDataObject;
  public map: Map;
  public systems: { id: number; show: boolean; name: string }[];
  public objectTypes: { id: number; show: boolean; name: string }[][];
  public nomenclatureFilters: any[];
  public layerList: any[];
  public layerSelectableList: any[];
  public layerSources: object;
  public layerStyles: object;
  public layerFilters: object;
  public conditionalFilters: object;
  public tagRefs: object;
  public showLabelLayer: boolean;
  public symbolLayer: SymbolLayers;
  public geocodeApi: GeocodeAPI;
  public activedrawing: ActiveDrawingService;
  public mapService: MapService;
  public mbdraw: MapboxDraw;
  public polygonAttr: any[];
  public showContainer: boolean;
  public zoomScale: string;
  public geojson: any;
  public linestring: any;
  public showByConfig: boolean;
  public marker: mapboxgl.Marker;
  public popup: mapboxgl.Popup;
  public markerCoords: LngLatLike;
  public breakDownMarker: mapboxgl.Marker;

  public measureMode: boolean;
  public showByMobileDevice: boolean;
  public mouseLngLat: any;
  public touchLngLat: any;
  public positions: any[];
  public conditions: any[];
  public operationals: any[];
  public breakDownSystems: { id: number; show: boolean; name: string }[];
  public turf = require("@turf/turf");
  public toggleChangeBreakdownLocation: boolean;

  @Watch("showLabelLayer")
  onZoomChange(zoomValid: boolean) {
    this.symbolLayer.setLayersByParams(
      this.map,
      this.systems,
      this.objectTypes,
      zoomValid
    );
  }
  @Watch("predefinedBreakdownLayers")
  onpredefinedBreakdownLayersChange(value) {
    if (value == true) {
      this.setActiveBreakdownLayer("none");
      this.setDefaultBreakdownLayer("visible");
    }
    if (value == false) {
      this.setActiveBreakdownLayer("visible")
      this.setDefaultBreakdownLayer("none");
    }
    // this.switchBreakdownSource()
  }

  @Watch("data.selectionData")
  onSelectionChange(data: object[]) {
    if (this.map.getZoom() >= 10) {
      this.mapSelectionUpdate(data);
    }
    // this.onMarkerReset();
  }
  @Watch("markerCoords")
  onChange(data: object[]) {
    if (data.length > 1) {
      let bounds = this.map.getBounds();
      let sw = this.map.project(bounds.getSouthWest().toArray());
      let ne = this.map.project(bounds.getNorthEast().toArray());
      const features = this.map.queryRenderedFeatures(
        [
          [sw.x, sw.y],
          [ne.x, ne.y],
        ],
        {
          layers: [layer.WATERLINES],
        }
      );



      let markerPosition = this.turf.point(data);

      let threshold = 0.003;
      for (let feature of features) {
        let snapped = this.turf.nearestPointOnLine(feature, markerPosition);

        let distance = this.turf.distance(markerPosition, snapped);

        if (distance <= threshold) {
          this.marker.setLngLat(snapped.geometry.coordinates);
          this.clearSel();
          this.addFtrToSel(feature);
          break;
        }
      }
    }
  }

  @Watch("$store.getters.token")
  onBearerTokenPresent(data: string) {
    this.addMapWMSLayers();
    this.loadNomenclatures();
    this.getMapDefinitionSettings();
    this.loadTagRefByLang();
    this.loadValveObjects();
  }
  @Watch("$store.getters.breakdownCoordinatesChangeMode")
  onBreakdownCoordinatesChangeMode(data: boolean) {
    this.toggleChangeBreakdownLocation = data;

  }

  @Watch("zone_target_attribute")
  onTargetAttrChange(target: object) {
    this.setZoneAttributes(target["osm_id"], target["key_value"]);
  }

  @Watch("search_feature")
  onSearchFeatureChange(feature: object) {
    this.centerToFeature(feature);
  }

  @Watch("search_cadastre")
  onSearchCadastreChange(feature: any) {
    const bbox = feature.bbox;
    this.map.fitBounds(bbox, { duration: 1000, offset: [0, 0] });
  }

  constructor() {
    super();
    this.data = {
      zoom: this.$store.getters.last_map_zoom ?? 10,
      center: JSON.parse(process.env.VUE_APP_OSM_CENTER),
      maxBounds: common.OSM_MAX_BOUNDS,
      showMap: true,
      isSourceLoaded: false,
      isBoxZoom: false,
      isDragPan: false,
      selectionList: [],
      selectionData: [],
      highlightedId: -1,
      highlightedSrc: "",
      highlightedSrcLayer: "",
      windowSelection: { start: null, active: true, startX: 0, startY: 0 },
      boxMode: "select",
      zones: source.ZONES_INIT_OBJECT,
      zones_breakdown: source.ZONES_INIT_OBJECT_BREAKDOWN,
      editZoneAttr: false,
      editZoneGeom: false,
      deletedZones: [],
      wsmlayers: [],
      analizeNetwork: false,
    };

    this.layerList = [];
    this.layerSelectableList = [];
    this.layerStyles = {};
    this.layerFilters = {};
    this.layerSources = {};
    this.nomenclatureFilters = [];
    this.conditionalFilters = {};
    this.tagRefs = {};
    this.positions = [];
    this.conditions = [];
    this.operationals = [];
    this.toggleChangeBreakdownLocation = false;

    this.systems = common.SYSTEMS;
    this.objectTypes = common.OBJECTS;
    this.breakDownSystems = common.BREAKDOWN_SYSTEMS;
    this.showLabelLayer = true;
    this.symbolLayer = new SymbolLayers();
    this.geocodeApi = new GeocodeAPI();
    this.activedrawing = new ActiveDrawingService();
    this.mapService = new MapService();
    this.mbdraw = tools.MAPBOX_DRAW_CONTROL;
    this.polygonAttr = [];
    this.showContainer = false;
    this.zoomScale = "";
    this.geojson = { type: "FeatureCollection", features: [] };
    this.linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };
    this.showByConfig =
      Number(process.env.VUE_APP_SHOW_ACTIVE_DRAWING) === 1 ? true : false;

    this.showByMobileDevice = this.$store.getters.isMobile;
    var markerScale = this.showByMobileDevice ? 2 : 1.0;
    this.marker = new mapboxgl.Marker({
      offset: [0, -35.8],
      draggable: true,
      anchor: "top",
      scale: markerScale,
    });
    this.breakDownMarker = new mapboxgl.Marker({
      offset: [0, -35.8],
      draggable: false,
      anchor: "top",
      scale: 1,
      color: "#d92d09",
    });
    this.markerCoords = [0, 0];
    this.measureMode = false;
    this.popup = new mapboxgl.Popup({
      closeOnClick: false,
      offset: [0, 0],
    });
    this.mouseLngLat = [];
    this.touchLngLat = [];
  }

  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
    this.map = new mapboxgl.Map(layer.MAP_INIT_LAYER);
    if (
      this.$store.getters.last_map_center &&
      this.$store.getters.last_map_zoom
    ) {
      this.map.setCenter(this.$store.getters.last_map_center as LngLatLike);
      this.map.setZoom(this.$store.getters.last_map_zoom as number);
    } else {
      this.map.setCenter(this.data.center as LngLatLike);
      this.map.setZoom(this.data.zoom as number);
    }

    const watermeterID = getParameter("watermeterID", null);
    if (watermeterID) {
      this.focusOnTarget(watermeterID);
    }

    // On map actions - on load
    this.map.on("load", () => {
      this.zoomScale = `${calcScale(
        this.map.getZoom(),
        this.map.getCenter().lat
      )}`;
    });

    // On map actions - on zoomstart
    this.map.on("zoomstart", () => {
      const zoomLevel = this.map.getZoom();
      if (zoomLevel >= 15) {
        this.showLabelLayer = true;
      } else {
        this.showLabelLayer = false;
      }
      this.zoomScale = `${calcScale(zoomLevel, this.map.getCenter().lat)}`;
      this.mapService.showCenterAndZoom(this.zoomScale, this.mouseLngLat);

      this.saveMapPosition();
    });

    this.map.on("touchmove", (e) => {
      const zoomLevel = this.map.getZoom();
      this.touchLngLat = e.lngLat;
      if (zoomLevel >= 15) {
        this.showLabelLayer = true;
      } else {
        this.showLabelLayer = false;
      }
      this.zoomScale = `${calcScale(zoomLevel, this.map.getCenter().lat)}`;
      this.mapService.showCenterAndZoom(this.zoomScale, this.touchLngLat);

      this.saveMapPosition();
    });

    this.map.on("dragend", () => {
      this.saveMapPosition();
    });

    this.map.on("mousemove", (e) => {
      this.mouseLngLat = e.lngLat;

      this.mapService.showCenterAndZoom(this.zoomScale, this.mouseLngLat);
    });

    //Add scale control
    let scale = new mapboxgl.ScaleControl({
      maxWidth: 100,
      unit: "metric",
    });
    this.map.addControl(scale);
    this.activedrawing.containerUI();
    // Functional drawing buttons
    if (!this.showByMobileDevice) {
      this.map.addControl(this.mbdraw, "bottom-left");

      const ctrlGroupBtns = this.activedrawing.setDrawingControlGroup();
      ctrlGroupBtns.editbtn.onclick = this.zoneStartReshape;
      ctrlGroupBtns.editabtn.onclick = this.zoneStartEditAttr;
      ctrlGroupBtns.savebtn.onclick = this.zoneSaveEdits;
      ctrlGroupBtns.cancelbtn.onclick = this.zoneCancelEdits;
      ctrlGroupBtns.measurebtn.onclick = this.measureStartAction;
      ctrlGroupBtns.cancelMeasurebtn.onclick = this.measureEndAction;
      ctrlGroupBtns.analizebtn.onclick = this.startAnalizingNetwork;
      ctrlGroupBtns.cancelAnalizebtn.onclick = this.stopAnalizingNetwork;

      if (this.showByConfig) {
        this.activedrawing.noeditModeSetUI();
      } else {
        this.activedrawing.forbidByClientRights();
      }
    }

    // Add search control
    const geocoder_api = this.geocodeApi.getApiSource();
    const options = tools.GEOCODER_OPTIONS;
    options.accessToken = mapboxgl.accessToken;
    options.maplibregl = mapboxgl;
    const geocoderContainer = new MapboxGeocoder(geocoder_api, options);
    this.geocodeApi.setSearchGroup(geocoderContainer, this.map);

    // Add print control to the map
    if (!this.showByMobileDevice) {
      //@ts-ignore
      this.map.addControl(tools.EXPORT_CONTROL, "top-right");
    }

    //Add geolocate control to the map.
    this.map.addControl(tools.GEOLOCATE_CONTROL);

    //Add compass
    this.map.addControl(tools.COMPASS_CONTROL);

    // Events
    this.map.on("draw.create", this.newZone);
    this.map.on("draw.delete", this.delZone);
    this.map.on("draw.update", this.reshapeZone);

    this.map.on("click", layer.ZONES_POLYGON, this.zoneAttr);
    this.map.on("click", layer.ZONES_LINE, this.zoneAttr);
    this.map.on("click", layer.ZONES_POINT, this.zoneAttr);
    this.map.on("click", this.measureStart);
    this.map.on("mousedown", this.rightClick);

    this.map.on("mousedown", this.mDownBox);
    this.marker.on("dragend", () => {
      // Get the marker's current position
      let position = this.marker.getLngLat();
      this.markerCoords = [position.lng, position.lat];
    });
    //Breakdown events
    this.map.on("click", layer.WATERLINES, (e) => {
      if (
        !this.measureMode &&
        !this.data.editZoneAttr &&
        !this.data.editZoneGeom &&
        !e.originalEvent.shiftKey &&
        !this.checkElementHasClass("draw_toolbar_analize", "hidebtn") &&
        this.$store.getters.alertStatus
      ) {

        const lng = e.lngLat.lng;
        const lat = e.lngLat.lat;
        this.markerCoords = [lng, lat];
        this.onDragEnd();

      }
    });

    this.map.on("click", layer.ZONES_POLY_BREAKDOWN, (e) => {
      if (
        !this.measureMode &&
        !this.data.editZoneAttr &&
        !this.data.editZoneGeom &&
        !e.originalEvent.shiftKey &&
        this.data.selectionData.length === 1 &&
        this.$store.getters.polygonsPointConnectionMode
      ) {

        this.mapSelectionUpdatePoly(this.data.selectionData)

      }
    });
    this.map.on("click", layer.VALVEPOINTS, (e) => {
      if (
        !this.measureMode &&
        !this.data.editZoneAttr &&
        !this.data.editZoneGeom &&
        !e.originalEvent.shiftKey &&
        this.data.selectionData.length === 1
      ) {
        const aqType = (this.data.selectionData[0].props as any).aq_type;
        const regex =
          /((.*[Сс]пирателен.*[Кк]ран.*)|(.*[Кк]ран.*[Сс]пирателен.*))/gm;
        if (regex.test(aqType)) {
          const coords = this.data.selectionData[0].geom
            .coordinates as LngLatLike;
          this.map.flyTo({
            center: coords,
            offset: [0, -130],
            bearing: 0,
            pitch: 10,
            speed: 1.2,
            curve: 1,
            easing: (t) => t,
            essential: true,
          });
          this.onDragEndPOP();
        }
      }
    });
  }
  public onDragEndPOP() {
    // create the popup
    this.popup
      .setLngLat(this.data.selectionData[0].geom.coordinates)
      .setHTML(`<card  id="vue-popup-content"></card >`)
      .addTo(this.map);
    const PopupClass = Vue.extend(MapBoxCustomPopup);
    const popupClass = new PopupClass({
      propsData: {
        selectedElement: this.data.selectionData,
        positions: this.positions,
        conditions: this.conditions,
        operationals: this.operationals,
      },
    });

    popupClass.$mount("#vue-popup-content");

    popupClass.$on("valve-state-saved", (data) => {
      const valve = data.features[0];
      const geojsonsrc = this.map.getSource(source.VALVES);
      const ftrToUpdate = geojsonsrc._data.features.find((feature) => {
        return feature.properties.osm_id === valve.properties.osm_id;
      });
      ftrToUpdate.properties = valve.properties;
      geojsonsrc.setData(geojsonsrc._data);
      loadingHide();
      setTimeout(() => {
        this.popup.remove();
      }, 250);
    });
    this.popup._update();
  }
  public onDragEnd() {
    // create the popup
    this.popup
      .setLngLat(this.markerCoords)
      .setHTML(
        `Longitude: ${this.markerCoords[0]}<br/>Latitude: ${this.markerCoords[1]}`
      );
    this.marker
      .setLngLat(this.markerCoords)
      .setPopup(this.popup)
      .addTo(this.map);
  }
  public getMapDefinitionSettings() {
    getMapSettings()
      .then((response) => {
        const data = response.data as any;

        //Populate local data
        this.addLocalData(data);
        //Populate map data
        this.addMapData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public checkElementHasClass(elementId, className) {
    var element = document.getElementById(elementId);
    if (element && element.classList.contains(className)) {
      return true;
    } else {
      return false;
    }
  }
  public toggleMarker(coords = null) {
    if (coords !== null) {

      this.marker.setLngLat(coords).addTo(this.map);
    } else {

      this.markerCoords = this.map.getCenter();
      this.marker.setLngLat(this.markerCoords).addTo(this.map);
    }


  }


  public markerForSelection(coords = null) {

    if (coords !== null) {

      this.toggleMarker(coords)
    } else {

      this.onMarkerReset()
      this.toggleMarker()
    }
  }

  public addBreakDownMarker(longitude, latitude, breakdown) {
    this.map.flyTo({
      center: [longitude, latitude],
      offset: [0, -130],
      bearing: 0,
      pitch: 10,
      speed: 1.2,
      curve: 1,
      easing: (t) => t,
      essential: true,
    });


    this.breakDownMarker.setLngLat([longitude, latitude]).setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<h3>TEST</h3>
        <p>Авария No.</p><p>${breakdown.workCardNo}</p>
        <p>Random popup</p>
        <p>Random popup</p>
        <p>Random popup</p>`
        )
    ).addTo(this.map)
  }
  public handleAddressSubmitted(address) {
    // Do something with the address

    // const geocoder_api = this.geocodeApi.getApiSource();
    // const options = tools.GEOCODER_OPTIONS;
    // options.accessToken = mapboxgl.accessToken;
    // options.maplibregl = mapboxgl;
    // const geocoderContainer = new MapboxGeocoder(geocoder_api, options);
    this.geocodeApi.activateGeocoderWithSearchString(address)


    //  this.geocodeApi.setSearchGroup(address, this.map);
  }
public  callRefreshRegionsFunction(){
  (this.$refs.sidebarBreakdownManeger as any).getWorkRegions();
}

  public addLocalData(data) {
    data.forEach((def) => {
      this.layerList.push({
        id: def.layer.id,
        name: def.layer.layerName,
        type: def.layer.layerType.name,
        minZoom: def.layer.minZoom,
        maxZoom: def.layer.maxZoom,
        sourceLayer: def.layer.sourceLayer,
        selectable: def.selectable,
      });
      this.layerSources[def.layer.id] = {
        sourceId: def.source.sourceName,
        sourceObj: JSON.parse(def.source.sourceObject),
      };
      this.layerStyles[def.layer.id] = {
        layout: JSON.parse(def.style.layout),
        paint: JSON.parse(def.style.paint),
      };
      if (def.filter) {
        this.layerFilters[def.layer.id] = {
          filter: JSON.parse(def.filter.condition),
          shortName: def.filter.shortName,
        };
      }
      if (def.selectable) {
        this.layerSelectableList.push({
          id: def.layer.id,
          name: def.layer.layerName,
        });
      }
    });
  }

  public addMapData() {
    //Sources
    Object.keys(this.layerSources).forEach((key) => {
      const source = this.layerSources[key];
      if (!this.map.getSource(source.sourceId)) {
        this.map.addSource(source.sourceId, source.sourceObj as any);
      }
    });

    // Layers
    this.layerList.forEach((l) => {
      const layerObj = {
        id: l.name,
        source: this.layerSources[l.id].sourceId,
        type: l.type,
        layout: this.layerStyles[l.id].layout,
        paint: this.layerStyles[l.id].paint,
        minzoom: l.minZoom ?? 1,
        maxzoom: l.maxZoom ?? 24,
      } as any;
      if (l.sourceLayer) {
        layerObj["source-layer"] = l.sourceLayer;
      }
      if (this.layerFilters[l.id]) {
        layerObj.filter = this.layerFilters[l.id].filter;
      }
      this.map.addLayer(layerObj);
    });

    this.loadSecondarySourceData();
    this.loadBreakdownSourceData();
    this.setDefaultBreakdownLayer("none");
    this.setSelectionLayers();
    this.setMapLayers();
  }

  public filterActiveLayersByID(breakdown_id) {
    this.clearSel()
    const selected = this.data.zones.features.filter(features => features?.properties?.breakdown_id === breakdown_id);
    if (selected.length > 0) {
      for (let feature  of selected){
      const selectionDataItem = {
        id: feature.properties?.osm_id,
        properties: feature.properties,
        geometry: feature.geometry,
        source: source.ZONES,
        // sourceLayer:layer.ZONES_POLYGON,
      };
      this.addFtrToSel(selectionDataItem);
    }

    }
  }


  public loadSecondarySourceData() {
    if (this.showByConfig) {
      getPolygons(true).then((response) => {
        this.data.zones.features = response.data;
        const polygonSrcc = this.map.getSource(source.ZONES) as GeoJSONSource;
        polygonSrcc.setData({ type: "FeatureCollection", features: response.data });
      });


    }
  }
  public loadBreakdownSourceData() {
    if (this.showByConfig) {
      getPolygons().then((res) => {
        this.data.zones_breakdown.features = res.data;

        const polygonSrc = this.map.getSource(source.ZONES_BREAKDOWN) as GeoJSONSource;
        polygonSrc.setData({ type: "FeatureCollection", features: res.data });
      });

    }
  }


  public loadValveObjects() {
    getValveObjects()
      .then((res) => {
        const valvesSrc = this.map.getSource(source.VALVES) as GeoJSONSource;
        valvesSrc.setData(res.data);
      })
      .finally(() => loadingHide());
  }

  public loadNomenclatures() {
    getSystems().then((res) => {
      this.systems = res.data as any;
    });
    getObjects().then((res) => {
      this.objectTypes = res.data as any;
    });
    getPositions().then((res) => {
      this.positions = res.data as any;
    });
    getConditions().then((res) => {
      this.conditions = res.data as any;
    });
    getOperationals().then((res) => {
      this.operationals = res.data as any;
    });
  }

  public loadTagRefByLang() {
    getTagRefByLang().then((res) => {
      const data = res.data as any;
      data.forEach((x) => {
        this.tagRefs[x.code] = x.text;
      });
      this.mapTagRefs(this.tagRefs);
    });
  }

  public setMapLayers() {
    let vstate;
    if (this.systems[0].show) {
      // Water
      vstate = this.objectTypes[0][0].show ? "visible" : "none"; // Lines
      this.map.setLayoutProperty(layer.WATERLINES, "visibility", vstate);
      vstate = this.objectTypes[0][1].show ? "visible" : "none"; // Points
      this.map.setLayoutProperty(layer.WATERPOINTS, "visibility", vstate);
      this.map.setLayoutProperty(layer.VALVEPOINTS, "visibility", vstate);
      vstate = this.objectTypes[0][2].show ? "visible" : "none"; // Areal
      this.map.setLayoutProperty(layer.AREAL_POLY_WATER, "visibility", vstate);
      this.map.setLayoutProperty(layer.AREAL_LINE_WATER, "visibility", vstate);
      vstate = this.objectTypes[0][3].show ? "visible" : "none"; // Watermeters
      this.map.setLayoutProperty(
        layer.WATERMETERS_RESIDENTIAL,
        "visibility",
        vstate
      );
      vstate = this.objectTypes[0][4].show ? "visible" : "none"; // Watermeters
      this.map.setLayoutProperty(
        layer.WATERMETERS_PUBLIC,
        "visibility",
        vstate
      );
    } else {
      this.map.setLayoutProperty(layer.WATERLINES, "visibility", "none");
      this.map.setLayoutProperty(layer.WATERPOINTS, "visibility", "none");
      this.map.setLayoutProperty(layer.VALVEPOINTS, "visibility", "none");
      this.map.setLayoutProperty(layer.AREAL_POLY_WATER, "visibility", "none");
      this.map.setLayoutProperty(layer.AREAL_LINE_WATER, "visibility", "none");
      this.map.setLayoutProperty(
        layer.WATERMETERS_RESIDENTIAL,
        "visibility",
        "none"
      );
      this.map.setLayoutProperty(
        layer.WATERMETERS_PUBLIC,
        "visibility",
        "none"
      );
    }

    this.symbolLayer.setLayersByParams(
      this.map,
      this.systems,
      this.objectTypes,
      this.showLabelLayer
    );
    this.clearSel();
  }
  public setBreakDownLayers() {
    let vstate;
    vstate = this.breakDownSystems[0].show ? "visible" : "none";

    this.map.setLayoutProperty(layer.BREAKDOWN_POINTS, "visibility", vstate);
    this.map.setLayoutProperty(
      layer.BREAKDOWN_POINTS_LABLE,
      "visibility",
      vstate
    );
  }

  public hideDrawnLayer() {
    this.map.setLayoutProperty(layer.ZONES_LINE, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POINT, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_LINE_LABEL, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POINT_LABEL, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POLYGON_LABEL, "visibility", "none");
  }

  public setActiveBreakdownLayer(value) {
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", value);
  }
  public setDefaultBreakdownLayer(value) {
    this.map.setLayoutProperty(layer.ZONES_POLY_BREAKDOWN, "visibility", value);
  }

  public showDrawnLayer() {
    this.map.setLayoutProperty(layer.ZONES_LINE, "visibility", "visible");
    this.map.setLayoutProperty(layer.ZONES_POINT, "visibility", "visible");
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", "visible");
    this.map.setLayoutProperty(layer.ZONES_LINE_LABEL, "visibility", "visible");
    this.map.setLayoutProperty(
      layer.ZONES_POINT_LABEL,
      "visibility",
      "visible"
    );
    this.map.setLayoutProperty(
      layer.ZONES_POLYGON_LABEL,
      "visibility",
      "visible"
    );
  }

  public newZone(e) {
    const id = e.features[0].id;
    this.mbdraw.setFeatureProperty(id, "id", id);
    this.mbdraw.setFeatureProperty(id, "update", ["new"]);
  }

  public delZone(e) {
    const ftrpropid = e.features[0].properties.osm_id;
    this.data.deletedZones.push(ftrpropid);
  }

  public reshapeZone(e) {
    const ftr = e.features[0];
    const id = e.features[0].id;
    const upd = ftr.properties.update;
    if (upd) {
      if (upd.indexOf("reshape") === -1) {
        upd.push("reshape");
        this.mbdraw.setFeatureProperty(id, "update", upd);
      }
    } else {
      this.mbdraw.setFeatureProperty(id, "update", ["reshape"]);
    }
  }

  public zoneStartReshape() {
    //Hide symbol layers
    this.symbolLayer.hideAll(this.map);
    //Clear selected assets
    this.clearSel();
 
    this.mbdraw.set(this.data.zones);
    // this.setZonesLayoutProperty("none");
    this.data.editZoneGeom = true;
    this.activedrawing.reshapeModeSetUI();
  }

  public zoneStartEditAttr() {
    //Hide symbol layers
    this.symbolLayer.hideAll(this.map);
    //Clear selected assets
    this.clearSel();

    this.data.editZoneAttr = true;
    this.mapZoneAttrMode();

    this.activedrawing.attributeModeSetUI();
  }

  public zoneSaveEdits() {

    if (this.data.editZoneGeom) {
      this.data.zones = this.mbdraw.getAll();
      this.data.editZoneGeom = false;
      (this.map.getSource(source.ZONES) as GeoJSONSource).setData(
        this.data.zones

      );
      this.mbdraw.deleteAll();
      this.setZonesLayoutProperty("visible");
    }
    this.data.editZoneAttr = false;
    this.mapZoneAttrMode();

    this.activedrawing.noeditModeSetUI();
    this.data.zones.features.forEach((z) => {
      if (!z.properties!.update) {
        z.properties!.update = [];
      }
    });
    const output = { deleted: this.data.deletedZones, zones: this.data.zones };

    savePolygon(output).then((res) => {
      this.polygonAttr = [];
      this.data.zones.features.forEach((z) => {
        z.properties!.update = [];
      });
      getPolygons().then((res) => {
        this.data.zones.features = res.data;
        const polygonSrc = this.map.getSource(source.ZONES) as GeoJSONSource;
        polygonSrc.setData({
          type: "FeatureCollection",
          features: res.data,
        });
        this.mapZoneAttrs();
        this.symbolLayer.showAll(this.map);
      });
    });
  }

  public zoneCancelEdits() {
    this.mbdraw.deleteAll();
    this.data.editZoneGeom = false;
    this.data.editZoneAttr = false;

    this.setZonesLayoutProperty("visible");
    this.activedrawing.noeditModeSetUI();
    this.polygonAttr = [];

    this.mapZoneAttrs();
    this.mapZoneAttrMode();
    this.symbolLayer.showAll(this.map);
  }

  public measureStart(e) {
    const measureBtn = document.getElementById("draw_toolbar_measure");

    if (measureBtn?.classList.contains("hidebtn")) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [layer.MEASURE_POINTS],
      });

      // Remove the linestring from the group
      // So we can redraw it based on the points collection
      if (this.geojson.features.length > 1) this.geojson.features.pop();

      // Clear the Distance container to populate it with a new value
      let distanceContainer = document.getElementById("distance");
      distanceContainer!.innerHTML = "";

      // If a feature was clicked, remove it from the map
      if (features.length) {
        const id = features[0].properties.id;
        this.geojson.features = this.geojson.features.filter(function (point) {
          return point.properties.id !== id;
        });
      } else {
        var point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        this.geojson.features.push(point);
      }

      if (this.geojson.features.length > 1) {
        this.linestring.geometry.coordinates = this.geojson.features.map(
          function (point) {
            return point.geometry.coordinates;
          }
        );
        this.geojson.features.push(this.linestring);

        // Populate the distanceContainer with total distance
        let value = document.createElement("pre");
        value.textContent =
          this.$t("total_length") +
          turf.length(this.linestring).toLocaleString() +
          this.$t("unit_of_measure_km");
        distanceContainer!.appendChild(value);
      }

      const measureSrc = this.map.getSource(source.MEASURE) as GeoJSONSource;
      measureSrc.setData(this.geojson);
    }
  }

  public measureStartAction(e) {
    this.activedrawing.measureModeSetUI();
    this.disableSelectionLayers();
    this.map.getCanvas().style.cursor = "crosshair";
  }

  public measureEndAction(e) {
    this.activedrawing.noMeasureModeSetUI();
    this.geojson = {
      type: "FeatureCollection",
      features: [],
    };
    this.linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };

    let distanceContainer = document.getElementById("distance");
    distanceContainer!.innerHTML = "";

    const measureSrc = this.map.getSource(source.MEASURE) as GeoJSONSource;
    measureSrc.setData(this.geojson);
    this.map.getCanvas().style.cursor = "";

    this.setSelectionLayers();
  }

  public setZonesLayoutProperty(value) {
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", value);
    this.map.setLayoutProperty(layer.ZONES_POLY_BREAKDOWN, "visibility", "none");

    this.map.setLayoutProperty(layer.ZONES_LINE, "visibility", value);
    this.map.setLayoutProperty(layer.ZONES_POINT, "visibility", value);
  }

  public addMapWMSLayers() {
    getMapWMSLayers().then((res) => {
      //Manualy add main osm layer
      this.data.wsmlayers.push({
        id: "OSM",
        text: this.$t("main_map"),
        show: true,
        zoomExtent: 0,
      });

      res.data.forEach((x) => {
        // Populate radio button component
        this.data.wsmlayers.push({
          id: x.secondary_id,
          text: x.name,
          show: x.show,
          zoomExtent: x.zoom_extent,
        });

        //Add provided WMS layers to the map
        this.map.addSource(x.secondary_id, {
          type: x.type,
          tiles: [x.url],
          tileSize: 256,
        });
        this.map.addLayer({
          id: x.secondary_id,
          type: x.type,
          source: x.secondary_id,
          metadata: {},
          paint: {},
          layout: {
            visibility: "none",
          },
        });
      });
    });
  }

  public wmsLayerChanged(selectedLayers) {
    this.data.wsmlayers.forEach((x) => {
      if (selectedLayers.includes(x.id)) {
        this.map.setLayoutProperty(x.id, "visibility", "visible");
      } else {
        this.map.setLayoutProperty(x.id, "visibility", "none");
      }
    });
  }

  public zoneAttr(e) {
    if (this.data.editZoneAttr) {
      const props = {
        properties: e.features[0].properties,
        layer: e.features[0].layer.id,
      };

      const foundObj: any = this.polygonAttr.find(
        (x) => x.properties.osm_id === e.features[0].properties.osm_id
      );

      if (!foundObj) {
        this.polygonAttr.push(props);

        this.mapZoneAttrs();
      }
    }
  }

  public setZoneAttributes(id, attrvalue) {
    for (let i = 0; i < this.data.zones.features.length; i++) {
      const props = this.data.zones.features[i].properties;
      if (props && props.osm_id && props.osm_id === id) {
        Object.keys(attrvalue).forEach((key) => (props[key] = attrvalue[key]));
        if (props.update) {
          if (props.update.indexOf("attr") === -1) props.update.push("attr");
        } else {
          props.update = ["attr"];
        }
        (this.map.getSource(source.ZONES) as GeoJSONSource).setData(
          this.data.zones
        );
      }
    }
  }

  public setSelectionLayers() {
    this.layerSelectableList.forEach((l) => {
      this.map.on("mouseenter", l.name, this.mEnter);
      this.map.on("mouseleave", l.name, this.mLeave);
      this.map.on("mousedown", l.name, this.mDown);
    });
  }

  public disableSelectionLayers() {
    this.layerSelectableList.forEach((l) => {
      this.map.off("mouseenter", l.name, this.mEnter);
      this.map.off("mouseleave", l.name, this.mLeave);
      this.map.off("mousedown", l.name, this.mDown);
    });
  }

  public addFtrToSel(ftr) {

    if (
      !this.data.editZoneGeom &&
      !this.data.editZoneAttr &&
      !this.data.analizeNetwork
    ) {
      const idx = this.data.selectionList.indexOf(ftr.id);
      if (idx === -1) {
        this.data.selectionList.push(ftr.id);
        this.data.selectionData.push({
          props: ftr.properties,
          src: ftr.source,
          geom: ftr.geometry,
          sourceLayer: ftr.sourceLayer,
        });
        this.map.setFeatureState(
          { source: ftr.source, id: ftr.id, sourceLayer: ftr.sourceLayer },
          { selected: true }
        );
      }
      this.populateSelectedDataToLayers();
    }
  }

  public addFtrToSelToggle(ftr) {
    const idx = this.data.selectionList.indexOf(ftr.id);
    if (idx === -1) {
      this.data.selectionList.push(ftr.id);
      this.data.selectionData.push({
        props: ftr.properties,
        src: ftr.source,
        geom: ftr.geometry,
        sourceLayer: ftr.sourceLayer,
      });
      this.map.setFeatureState(
        {
          source: ftr.source,
          id: ftr.id,
          sourceLayer: ftr.sourceLayer,
        },
        { selected: true }
      );
    } else {
      this.data.selectionList.splice(idx, 1);
      this.data.selectionData.splice(idx, 1);
      this.map.setFeatureState(
        {
          source: ftr.source,
          id: ftr.id,
          sourceLayer: ftr.sourceLayer,
        },
        { selected: false }
      );
    }
    this.populateSelectedDataToLayers();
  }

  public populateSelectedDataToLayers() {
    Object.keys(this.layerList).forEach((l) => {
      const layer = this.layerList[l];
      if (!layer.selectable && layer.name.toLowerCase().includes("select")) {
        this.map.setFilter(layer.name, [
          "in",
          "osm_id",
          ...this.data.selectionList,
        ] as any);
      }
    });
  }

  public clearSel() {
    this.data.selectionList.forEach((id, index) => {
      this.map.setFeatureState(
        {
          source: this.data.selectionData[index]["src"],
          id: id,
          sourceLayer: this.data.selectionData[index].sourceLayer,
        },
        { selected: false }
      );
    });
    this.data.selectionList = [];
    this.data.selectionData = [];
  }

  public resetSelBox() {
    this.map.dragPan.enable();
    this.map.boxZoom.enable();
    this.data.windowSelection.start = null;
  }

  public mEnter(e) {
    this.map.getCanvas().style.cursor = "pointer";
    if (e.features && e.features.length > 0) {
      const el = e.features[0];
      if (this.data.highlightedId > -1 && this.data.highlightedSrc !== "") {
        this.map.setFeatureState(
          {
            source: this.data.highlightedSrc,
            id: this.data.highlightedId,
            sourceLayer: this.data.highlightedSrcLayer,
          },
          { highlighted: false }
        );
      }
      this.data.highlightedId = el.id;
      this.data.highlightedSrc = el.source;
      this.data.highlightedSrcLayer = el.sourceLayer;
      this.map.setFeatureState(
        { source: el.source, id: el.id, sourceLayer: el.sourceLayer },
        { highlighted: true }
      );
    }
  }

  public mLeave() {
    this.map.getCanvas().style.cursor = "";
    if (this.data.highlightedId > -1) {
      this.map.setFeatureState(
        {
          source: this.data.highlightedSrc,
          id: this.data.highlightedId,
          sourceLayer: this.data.highlightedSrcLayer,
        },
        { highlighted: false }
      );
    }
    this.data.highlightedId = -1;
    this.data.highlightedSrc = "";
    this.data.highlightedSrcLayer = "";
  }

  public mDown(e) {
    if (e.features.length > 0) {
      if (this.data.analizeNetwork) {
        this.loadValvesData(e.features[0]);
      }
      if (e.originalEvent.shiftKey
        //for multiple selection on moble 
        //  || this.$store.getters.isMobile
      ) {

        this.addFtrToSelToggle(e.features[0]);
      } else {
        this.clearSel();
        this.addFtrToSel(e.features[0]);
      }

      this.$emit("Selection Changed", this.data.selectionData);
    }
  }

  public rightClick(e) {
    if (e.originalEvent.button === 2) {
      this.clearSel();
    }
  }

  public mDownBox(e) {
    if (e.originalEvent.shiftKey) {
      this.data.isDragPan = this.map.dragPan.isEnabled();
      this.data.isBoxZoom = this.map.boxZoom.isEnabled();
      this.map.dragPan.disable();
      this.map.boxZoom.disable();
      this.data.windowSelection.start = e.lngLat;
      this.data.windowSelection.startX = e.point.x;
      this.data.windowSelection.startY = e.point.y;
      this.map.on("mouseup", this.mUpBox);
    }
  }

  public mUpBox(e) {
    if (this.data.windowSelection.start) {
      if (this.data.windowSelection.active) {
        if (
          Math.abs(e.point.x - this.data.windowSelection.startX) > 5 ||
          Math.abs(e.point.x - this.data.windowSelection.startX) > 5
        ) {
          const minlng = Math.min(
            this.data.windowSelection.start.lng,
            e.lngLat.lng
          );
          const minlat = Math.min(
            this.data.windowSelection.start.lat,
            e.lngLat.lat
          );
          const maxlng = Math.max(
            this.data.windowSelection.start.lng,
            e.lngLat.lng
          );
          const maxlat = Math.max(
            this.data.windowSelection.start.lat,
            e.lngLat.lat
          );
          if (this.data.boxMode === "select") {
            const ll = this.map.project([minlng, minlat]);
            const ur = this.map.project([maxlng, maxlat]);

            const features = this.map.queryRenderedFeatures(
              [
                [ll.x, ll.y],
                [ur.x, ur.y],
              ],
              {
                layers: this.layerSelectableList.map((x) => x.name),
              }
            );
            features.forEach((ftr) => {
              this.addFtrToSel(ftr);
            });
          } else if (this.data.boxMode === "export") {
            this.data.boxMode = "select";
          }
        }
      }
      this.resetSelBox();
      this.map.off("mouseup", this.mUpBox);
    }
  }

  public scaleChanged(newScale) {
    const newMapZoom = calcZoom(newScale, this.map.getCenter().lat);
    this.map.zoomTo(newMapZoom, { duration: 1000, offset: [0, 0] });
    this.zoomScale = newScale;
    this.mapService.showCenterAndZoom(this.map, newScale);
  }

  public visibilityChanged() {
    this.showContainer = false;
  }
  public closeLocationPopup() {
    this.removeBreakdownMarker()
    this.onMarkerReset()
    this.clearSel()

  }
  public onMarkerReset() {
    this.marker.remove();
    this.markerCoords = [0, 0];
  }
  public removeBreakdownMarker() {
    this.breakDownMarker.remove();
  }


  public loadValvesData(data) {
    loadingShow();
    const target = {
      lon: data.geometry.coordinates[0][0],
      lat: data.geometry.coordinates[0][1],
      osmId: data.id,
    };

    getAnalizeByPosition(target)
      .then((res) => {
        this.clearSel();

        this.map.setLayoutProperty(layer.ANALIZELINES, "visibility", "visible");
        this.map.setLayoutProperty(
          layer.ANALIZEPOINTS,
          "visibility",
          "visible"
        );

        this.map.setFilter(layer.ANALIZELINES, [
          "in",
          "osm_id",
          ...res.data,
        ] as any);
        this.map.setFilter(layer.ANALIZEPOINTS, [
          "in",
          "osm_id",
          ...res.data,
        ] as any);
      })
      .finally((err) => {
        loadingHide();
      });
  }

  startAnalizingNetwork() {
    this.clearSel();
    this.activedrawing.analizeModeSetUI();
    this.data.analizeNetwork = true;
    this.map.setLayoutProperty(layer.ANALIZELINES, "visibility", "none");
    this.map.setLayoutProperty(layer.ANALIZEPOINTS, "visibility", "none");
  }

  stopAnalizingNetwork() {
    this.activedrawing.noAnalizeModeSetUI();
    this.data.analizeNetwork = false;
  }

  focusOnTarget(watermeterID) {
    searchByPropertyValue(watermeterID).then((res) => {
      const watermeter = res.data.find((x) =>
        x.layer.layerName.includes("watermeter")
      );
      const item = watermeter.assets[0];
      const source = watermeter.source;
      const layer = watermeter.layer;
      this.objectTypes[0][3].show = true;
      this.objectTypes[0][4].show = true;
      this.setMapLayers();
      const selectedData: object = { item, source, layer };
      this.$store.dispatch("map/changeSearchFeature", selectedData);
    });
  }
  centerToWorkRegion(bbox) {
    this.map.fitBounds(bbox, { duration: 1000, offset: [0, 0] })

  }
  centerToFeature(feature) {
    //Clear if any selected assets
    this.clearSel();

    //Build object for the upcoming selection
    const target = {
      id: feature.item.osm_id,
      properties: feature.item,
      geometry: feature.item.way,
      source: feature.source.sourceName,
      sourceLayer: feature.layer.sourceLayer,
    };

    //Set map bbox
    const bbox = JSON.parse(feature.item.bbox);
    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      { duration: 1000, offset: [0, 0] }
    );

    //Force select on found asset
    setTimeout(() => {
      this.addFtrToSel(target);
    }, 2500);
  }

  public mapSelectionUpdate(data) {
    this.$store.dispatch("map/changeMapSelection", data).then(() => {
      this.$forceUpdate();
    });
  }
  public mapSelectionUpdatePoly(data) {
    this.$store.dispatch("map/changeMapSelectionPoly", data).then(() => {
      this.$forceUpdate();
    });
  }

  public mapTagRefs(data) {
    this.$store.dispatch("map/changeMapTagRefs", data).then(() => {
      this.$forceUpdate();
    });
  }

  mapZoneAttrs() {
    this.$store.dispatch("map/changeZonesAttributes", this.polygonAttr);
  }

  mapZoneAttrMode() {
    this.$store.dispatch(
      "map/changeZonesAttributesMode",
      this.data.editZoneAttr
    );
  }

  saveMapPosition() {
    this.$store
      .dispatch("map/changeMapCenter", this.map.getCenter())
      .then(() => {
        this.$forceUpdate();
      });
    this.$store.dispatch("map/changeMapZoom", this.map.getZoom()).then(() => {
      this.$forceUpdate();
    });
  }
  get getElement() {
    const element = this.data.selectionData;
    if (element.length > 0) {
      return element[0].props;
    }
    return {};
  }
}
