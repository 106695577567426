
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { VMap } from "@/components/";

@Component({
  components: {
    VMap
  },
  computed: {
    ...mapGetters(["isLogged"]),
  },
})
export default class MMap extends Vue {
  constructor() {
    super();
  }
}
