/* eslint-disable */

const WATERLINES = 'src_lines_w';
const WATERPOINTS = 'src_points_w';
const AREALOBJECTS = 'areal_objects_src';
const ZONES = "zones_src";
const ZONES_BREAKDOWN = "zones_breakdown_src";
const WATERMETERS_RESIDENTIAL = "src_watermeters_res";
const WATERMETERS_PUBLIC = "src_watermeters_pub";
const DMAZONES = "src_dma_zones";
const MEASURE = "measure_src";
const VALVES = "src_valves_w_p";
const OSMAP = 'OSM';

const ZONES_INIT_OBJECT = {
  type: "FeatureCollection",
  features: [],
} as any;
const ZONES_INIT_OBJECT_BREAKDOWN = {
  type: "FeatureCollection",
  features: [],
} as any;
export default {
  WATERLINES,
  WATERPOINTS,
  AREALOBJECTS,
  WATERMETERS_RESIDENTIAL,
  WATERMETERS_PUBLIC,
  ZONES_INIT_OBJECT,
  ZONES_INIT_OBJECT_BREAKDOWN,
  ZONES,
  ZONES_BREAKDOWN,
  DMAZONES,
  MEASURE,
  VALVES,
  OSMAP
}