
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { ITableProps } from "@/interfaces";
import { getBreakdownsList, getWorkRegions, getBreakdownsListTest,disconnectBreakdown} from "@/api/map";
import moment from "moment";
import BreakdownAttributes from "@/components/map/BreakdownDialogs/BreakdownAttributes.vue";
import mapboxgl, { LngLatLike } from "mapbox-gl";

import ConfirmPolyPointConnection from "@/components/map/BreakdownDialogs/ConfirmPolyPointConnection.vue";
import ChangeBreakdownLocationPopup from "@/components/map/BreakdownDialogs/ChangeBreakdownLocationPopup.vue";


@Component({
  components: { BreakdownAttributes, ConfirmPolyPointConnection, ChangeBreakdownLocationPopup },
  computed: {
    ...mapGetters(["isLogged", "last_map_center", "last_map_zoom", "selected_poly", "breakdownCoordinatesChangeMode", "polygonsPointConnectionMode","breakdownId"]),

  },
})
export default class ISidebarBreakdownManeger extends Vue {
  public toggleIcon: string;
  public panel: number[];
  public items: string[];
  public tableProps: ITableProps;
  public selectedBreakdown: any | null;
  public expanded: any | null;
  public attributesDialog: boolean;
  public attributeDialogDelete: boolean;
  public confirmPolyPointDialog: boolean;
  public confirmPolyPointDialogDelete: boolean;
  public workRegions: any | null;
  public selectedRegion: any | null;
  public activeConnectionId: number | null;
  public markerSelectionCoords: LngLatLike;
  public onlyActiveBreakdowns: any;
  public showMenu: boolean;
  public menus: any;
  public regionsType: any;



  @Prop() readonly wsmlayers: object[];


  @Watch("selectedRegion")
  onselectedRegionChange(selectedRegion: any) {
    // this.$emit("center-town-to-working-region", selectedRegion.bbox);

    this.getBreakdowns();
  }

  @Watch("$store.getters.polygonsPointConnectionMode")
  onpolygonsPointConnectionModeChange(status: boolean) {
    console.log(status)

    if(status == false){
      console.log(status)
      console.log("polygonsPointConnectionMode")

    this.activeConnectionId = null;
      this.selectedBreakdown = null;
      this.$emit("remove-breakdown-marker")
    this.$store.commit("app/SET_PREDEFINED_BREAKDOWN_LAYERS", false);
  }
  if(status ==true){
    console.log(status)

    console.log("elselse")

    this.$store.commit("app/SET_PREDEFINED_BREAKDOWN_LAYERS", true);

  }

  }
  @Watch("breakdownCoordinatesChangeMode")
  onbreakdownCoordinatesChangeModeChange(breakdownCoordinatesChangeMode: boolean) {
    this.getBreakdowns();
  }
  @Watch("onlyActiveBreakdowns")
  ononlyActiveBreakdownsChange(onlyActiveBreakdowns: boolean) {
    console.log(onlyActiveBreakdowns)
    this.getBreakdowns();
    this.getWorkRegions();


  }
  @Watch("selected_poly")
  onSelectedPoly(data: any[]) {
    if (data.length > 0) {
      this.confirmPolyPointDialog = true
    }
  }

  constructor() {
    super();
    this.markerSelectionCoords = [0, 0];
    this.attributesDialog = false;
    this.attributeDialogDelete = false;
    this.onlyActiveBreakdowns = { text: 'Региони само с активни аварии', value: true };
    this.showMenu = false;
    this.confirmPolyPointDialog = false;
    this.confirmPolyPointDialogDelete = false;
    this.selectedBreakdown = null;
    this.workRegions = [];
    this.selectedRegion = null
    this.activeConnectionId = null;
    this.toggleIcon = "mdi-format-align-justify";
    this.panel = [0];
    this.menus = {}
    // 
    this.expanded = [];
    this.regionsType = [
        { text: 'Региони само с активни аварии', value: true },
        { text: 'Региони с неактивни активни аварии', value: false },
        { text: 'Всички Региони', value: null }
      ]

    this.tableProps = {
      headers: [
        // { text: "Улица име", value: "streetName", valueType: "text", class: "successHeader" },
        {
          text: "Адрес",
          value: "addresss",
          valueType: "text",
          class: "successHeader",
        },
        // { text: "Авария No.", value: "workCardNo", valueType: "text", class: "successHeader" },
        // { text: "Завършена", value: "finished", valueType: "boolean", class: "successHeader" },


        // {
        //   text: "Начало ремонт на",
        //   value: "repairStart",
        //   valueType: "dateTimeToLocal",
        //   class: "successHeader",
        // },

        {
          sortable: false,
          text: "",
          value: "actions",
          valueType: "button",
          class: "text-ceter cols cols-2 successHeader",
          style: "width:100px; padding: 10px;",
          buttons: [
            {
              label: "",
              title: "Преглед",
              class: "successGradient",
              icon: "mdi-text-box-search-outline",
              style: "width: 100px; height: 30px;",
              click: "previewBreakdowns",
              id: "breakdownsDetailsPreview",
            },
          ],
        },
      ],

      data: [],
      filters: {},
      hasPaging: true,
      noms: {
        statuses: [],
        priorities: [],
        users: [],
      },
      pagination: {
        itemsPerPage: 10,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },

      props: {
        id: {
          type: String,
          default: "",
        },
        printAll: false,
        color: "primary",
      },

      sortColumn: [],
      sortDescending: [],
    }
  }

  mounted() {
    this.getWorkRegions();
  }

  public goToLocation(item) {
    if (typeof item.longitude === 'number' && !isNaN(item.longitude) &&
      typeof item.latitude === 'number' && !isNaN(item.latitude)) {
      this.$emit("add-breakdown-marker", item.longitude, item.latitude, item);
      this.$emit("show-active-poly", item.id);
    } else {
      console.error('Invalid coordinates:', item.longitude, item.latitude);
    }
  }

  public getWorkRegions() {
    getWorkRegions(this.onlyActiveBreakdowns.value).then((data) => {
      this.workRegions = data.data.resultData
    })

  }

  public getBreakdowns() {
    if (!this.selectedRegion || !this.selectedRegion.id) { return }
    getBreakdownsList(this.selectedRegion.id, this.onlyActiveBreakdowns.value).then((data) => {
      if (data.data.type == 1) {
        this.tableProps.data = data.data.resultData;
      }
      else { this.tableProps.data = [] }
      this.$forceUpdate();
    });

  }
  toggleMenu(id) {
    this.$set(this.menus, id, !this.menus[id]);
  }

  public regionNameFilter(item, queryText, itemText) {
    const text = item.regionName.toLowerCase();
    const searchText = queryText.toLowerCase();
    return text.includes(searchText);

  }


  public formatDateTimeToLocalTime(dateTime) {

    if (dateTime) {
      const stillUtc = moment.utc(dateTime).toDate();
      return moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    }

  }
  closeMenu(item) {
    if (this.menus[item.id] !== undefined) {
      this.menus[item.id] = false;
    }
  }

  ChangeBreakdownLocation(item) {
    this.goToLocation(item)
    this.markerSelectionCoords = [item.longitude + 0.0005, item.latitude - 0.00009]
    this.$emit('marker-for-selection', this.markerSelectionCoords)
    this.toggleSidebar()
    this.$store.commit("app/SET_BREAKDOWN_COORDS_MODE", true);
    this.$store.commit("app/SET_BREAKDOWN_ID", item.id);
    this.closeMenu(item);
  }

  public toggleSidebar() {
    const elem = document.getElementById("sidebar-breakdown-maneger");
    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          this.toggleIcon = "mdi-format-align-justify";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          this.toggleIcon = "mdi-format-align-justify";
        }, 550);
      }
      elem.className = classes.join(" ");
    }

  }
public disconnectBreakdownFromPolygon(item){
  disconnectBreakdown(item.id).then((res)=>{
    this.$emit("get-source-layer")
     this.closeMenu(item);

  })

}
  editItem(item) {
    this.attributesDialog = true;
    this.selectedBreakdown = item

  }
  closeDialogAttributes() {
    this.attributesDialog = false
    this.selectedBreakdown = null
    this.$emit("get-source-layer")
  }

  closeDialogPolyPointConnection() {
    this.confirmPolyPointDialog = false
    this.$emit("get-source-layer")
    this.$store.dispatch("map/changeMapSelectionPoly", []).then(() => {
      this.$forceUpdate();
    });
    this.activeConnectionId = null;
    this.selectedBreakdown = null;
    this.$store.commit("app/SET_CONNECTION_POLY_POINT_MODE", !this.$store.getters.polygonsPointConnectionMode);


    this.$emit("remove-breakdown-marker")

  }


  // connectBreakdownToPolygon(item) {
  //   this.goToLocation(item)
  //   if (this.activeConnectionId === item.id) {
  //     this.activeConnectionId = null;
  //     this.selectedBreakdown = null;
  //     this.$emit("remove-breakdown-marker")
  //   } else {
  //     this.activeConnectionId = item.id;
  //     this.selectedBreakdown = item
  //   }
  //   this.$store.commit("app/SET_CONNECTION_POLY_POINT_MODE", this.polyPointConnectionStatus);

  // }
  connectBreakdownToPolygon(item) {
    console.log("connectBreakdownToPolygon")
    this.goToLocation(item)
    if (this.activeConnectionId === item.id) {
      console.log("(this.activeConnectionId === item.id")
      this.activeConnectionId = null;
      this.selectedBreakdown = null;
      this.$emit("remove-breakdown-marker")
    } else {
      console.log("else")

      this.activeConnectionId = item.id;
      this.selectedBreakdown = item
    }
    // this.$store.commit("app/SET_CONNECTION_POLY_POINT_MODE", this.polyPointConnectionStatus);
    console.log(this.$store.getters.polygonsPointConnectionMode)
    this.$store.commit("app/SET_CONNECTION_POLY_POINT_MODE", !this.$store.getters.polygonsPointConnectionMode);
    console.log(this.$store.getters.polygonsPointConnectionMode)



  }

  getConnectionButtonClass(item) {
    return this.$store.getters.polygonsPointConnectionMode ? 'active-button' : 'inactive-button';
  }

  filteredData() {
    if (this.activeConnectionId) {
      return this.tableProps.data.filter(item => item.id === this.activeConnectionId);
    }
    return this.tableProps.data;
  }

}



