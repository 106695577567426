import i18n from "@/i18n";
import proj4 from "proj4";
import store from "@/store";

export default class MapService {
    showCenterAndZoom(newScale, lnglat) {
        const container = document.getElementById("center_and_zoom");
        container!.innerHTML = "";

        // Populate the distanceContainer with total distance
        const value = document.createElement("con");

        if (!store.getters.isMobile) {
            const reprojected = proj4("+proj=longlat +datum=WGS84 +no_defs").forward([lnglat.lng, lnglat.lat]);
            const lng = reprojected[0].toFixed(8);
            const lat = reprojected[1].toFixed(8);
            const scale = newScale;
            value.innerHTML = `${i18n.t('coordinates')}: ${lat}, ${lng} <br> 
                ${i18n.t('map_scale')}: 1:${scale} ${i18n.t('coordinate_system')}: WGS 84`;
            container!.appendChild(value);
        }
    }
}
