import i18n from "@/i18n";

const OSM_MAX_BOUNDS = [
    [41.34176252711261, 22.94906616210938],
    [43.7368715467591, 28.578872680664066],
];

const SYSTEMS = [//water
  { id: 0, show: true, name: `${i18n.t("water")}` }
];

const OBJECTS = [
  [//Water
    { id: 0, show: true, name: `${i18n.t("linear")}` },
    { id: 1, show: true, name: `${i18n.t("points")}` },
    { id: 2, show: true, name: `${i18n.t("areal_objects")}` },
    { id: 3, show: false, name: `${i18n.t("water_meters_residential")}` },
    { id: 4, show: false, name: `${i18n.t("water_meters_public")}` },
  ]
];

const GRID_TYPES = [
  { value: "Без антетка", key: 0 },
  { value: "A4-A3 кратка: обект, дата, мащаб", key: 1 },
  { value: "A4-A3 стандартна: кратка + възложител, изготвил", key: 2 },
  { value: "A4-A3 голяма: стандартна + подпис", key: 3 },
  { value: "A3 пълна", key: 4 },
]
const GRID_DATA = {
  Object: { description: `${i18n.t("object")}`, show: false, container: [1, 2, 3, 4] },
  Date: { description: `${i18n.t("date")}`, show: false, container: [1, 2, 3, 4] },
  Scale: { description: `${i18n.t("scale")}`, show: false, container: [1, 2, 3, 4] },
  Contractor: { description: `${i18n.t("contractor")}`, show: false, container: [2, 3, 4] },
  PreparedBy: { description: `${i18n.t("prepared_by")}`, show: false, container: [2, 3, 4] },
  Signature: { description: `${i18n.t("signature")}`, show: false, container: [3] },
  DocNumber: { description: `${i18n.t("doc_number")}`, show: false, container: [4] },
  SubObject: { description: `${i18n.t("sub_object")}`, show: false, container: [4] },
  System: { description: `${i18n.t("system")}`, show: false, container: [4] },
  CoordSystem: { description: `${i18n.t("coord_system")}`, show: false, container: [4] },
}

const BREAKDOWN_SYSTEMS = [
  { id: 0, show: false, name: `${i18n.t("breakdown_title")}` }
];

const INITIAL_EXPORT_ATTRIBUTES = {
  pageFormat: [297, 210],
  orientation: "landscape",
  fileType: "pdf",
  resolution: 200,
  scale: "",
  color: "",
  fontStyle: "Times New Roman",
  fontSize: 14,
  legend: false,
  drawingKey: 0,
  object: "",
  date: "",
  contractor: "",
  preparedBy: "",
  signature: "",
  docNumber: "",
  subObject: "",
  system: "",
  coordSystem: "",
};

export default {
    OSM_MAX_BOUNDS,
    SYSTEMS,
    OBJECTS,
    GRID_TYPES, GRID_DATA,
    INITIAL_EXPORT_ATTRIBUTES,
    BREAKDOWN_SYSTEMS
}