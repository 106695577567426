import moment from "moment";
import 'js-loading-overlay';

/**
   * @param {string} url
   * @returns {Object}
   */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual: string[]) {
  const newArray: string[] = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}


/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

function pad(n) { return n < 10 ? '0' + n : n }

export function formatDate(d) {
  if (d) {
    const date = moment(d)

    return date.format("DD.MM.YYYY");
  }

  return "";
}

export function formatDateTime(d) {
  if (d) {
    const date = moment(d)

    return date.format("DD.MM.YYYYг. HH:mmч.");
  }

  return "";
}

export function formatDateTimeLocal(d) {
  if (d) {
    const date = moment.utc(d).local()

    return date.format("DD.MM.YYYYг. HH:mmч.");
  }

  return "";
}

export function ISODateString(d) {
  if (d) {
    return pad(d.getDate()) + '.' +
      pad(d.getMonth() + 1) + '.' +
      d.getFullYear()
  } else {
    return ''
  }
}

export function dateToDominoString(d) {
  if (d) {
    return d.getFullYear() + '-' +
      pad(d.getMonth() + 1) + '-' +
      pad(d.getDate())
  } else {
    return ''
  }
}

export function translateDateToISO(date) {
  if (date) {
    return new Date(date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$3/$2/$1"));
  } else {
    return '';
  }
}

export function roundPrice(n, digits) {
  let negative = false
  if (digits === undefined) {
    digits = 0
  }

  if (n < 0) {
    negative = true
    n = n * -1
  }

  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(2)
  if (negative) {
    n = (n * -1).toFixed(2)
  }

  return n
}

export function filterQuery(query) {
  Object.keys(query).forEach(key => {
    if (query[key] === '') {
      delete query[key]
    }
  })
  return query
}

export function removeFromArray(arr: string[], strinToRemove: string) {
  for (let i = 0; i < arr.length; i++) {

    if (arr[i] === strinToRemove) {
      arr.splice(i, 1);
    }
  }

  return arr;
}

export function euroToWords(amount) {
  const words: string[] = []
  words[0] = ''
  words[1] = 'One'
  words[2] = 'Two'
  words[3] = 'Three'
  words[4] = 'Four'
  words[5] = 'Five'
  words[6] = 'Six'
  words[7] = 'Seven'
  words[8] = 'Eight'
  words[9] = 'Nine'
  words[10] = 'Ten'
  words[11] = 'Eleven'
  words[12] = 'Twelve'
  words[13] = 'Thirteen'
  words[14] = 'Fourteen'
  words[15] = 'Fifteen'
  words[16] = 'Sixteen'
  words[17] = 'Seventeen'
  words[18] = 'Eighteen'
  words[19] = 'Nineteen'
  words[20] = 'Twenty'
  words[30] = 'Thirty'
  words[40] = 'Forty'
  words[50] = 'Fifty'
  words[60] = 'Sixty'
  words[70] = 'Seventy'
  words[80] = 'Eighty'
  words[90] = 'Ninety'
  amount = amount.toString()
  const atemp = amount.split('.')
  const number = atemp[0].split(',').join('')
  const nLength = number.length
  let wordsString = ''
  if (nLength <= 9) {
    // eslint-disable-next-line
    const nArray: any = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    const receivedNArray: string[] = []
    for (let z = 0; z < nLength; z++) {
      receivedNArray[z] = number.substr(z, 1)
    }
    for (let a = 9 - nLength, h = 0; a < 9; a++, h++) {
      nArray[a] = receivedNArray[h]
    }
    for (let c = 0, j = 1; c < 9; c++, j++) {
      if (c === 0 || c === 2 || c === 4 || c === 7) {
        if (nArray[c] === 1) {
          nArray[j] = 10 + parseInt(nArray[j])
          nArray[c] = 0
        }
      }
    }
    // eslint-disable-next-line
    let value: any = '';
    for (let i = 0; i < 9; i++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        value = nArray[i] * 10
      } else {
        value = nArray[i]
      }
      if (value != 0) {
        wordsString += words[value] + ' '
      }
      if ((i === 1 && value != 0) || (i === 0 && value != 0 && nArray[i + 1] === 0)) {
        wordsString += 'Crores '
      }
      if ((i === 3 && value != 0) || (i === 2 && value != 0 && nArray[i + 1] === 0)) {
        wordsString += 'Lakhs '
      }
      if ((i === 5 && value != 0) || (i === 4 && value != 0 && nArray[i + 1] === 0)) {
        wordsString += 'Thousand '
      }
      if (i === 6 && value != 0 && (nArray[i + 1] != 0 && nArray[i + 2] != 0)) {
        wordsString += 'Hundred and '
      } else if (i === 6 && value != 0) {
        wordsString += 'Hundred '
      }
    }
    wordsString = wordsString.split('  ').join(' ')
  }
  return wordsString
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  // eslint-disable-next-line
  const i: any = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) as any);
  // @ts-ignore
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function withDecimal(n) {
  const nums = n.toString().split('.')
  const whole = euroToWords(nums[0])
  if (nums.length === 2) {
    const fraction = euroToWords(nums[1])
    return capitalizeFirstLetter(whole.toLowerCase()) + 'EURO and ' + fraction + 'cents'
  } else {
    return capitalizeFirstLetter(whole.toLowerCase()) + 'EURO'
  }
}

export function getParameter(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isEmptyObject(obj) {
  let isEmpty = true;
  for (const prop in obj) {
    if ({}.hasOwnProperty.call(obj, prop) && obj[prop] != "") {
      isEmpty = false;
    }
  }
  return isEmpty;
}

export function notify(vue, message, type) {
  let notifyType = "success";
  if ('undefined' !== typeof type) {
    switch (type) {
      case 0:
        notifyType = "danger";
        break;
      case 1:
        notifyType = "warning";
        break;
    }
  }

  vue.$notify({
    timeout: 2500,
    message: message,
    icon: "add_alert",
    horizontalAlign: 'right',
    verticalAlign: 'bottom',
    type: notifyType
  })
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

String.prototype.replaceAll = function (search, replacement) {
  let str1 = this.replace(search, replacement);
  // eslint-disable-next-line
  let str2 = this;
  while (str1 != str2) {
    str2 = str1;
    str1 = str1.replace(search, replacement);
  }
  return str1;
}

// @ts-ignore
Array.prototype.diff = function (arr2) {
  // eslint-disable-next-line
  const ret: any[] = [];
  this.sort();
  arr2.sort();
  for (let i = 0; i < this.length; i += 1) {
    if (arr2.indexOf(this[i]) > -1) {
      ret.push(this[i]);
    }
  }
  return ret;
};

export function base64ToPdfFile(base64) {
  const dbase64 = atob(base64);
  let length = dbase64.length;
  const bit8Arr = new Uint8Array(length);

  while (length--) {
    bit8Arr[length] = dbase64.charCodeAt(length)
  }

  return new File([bit8Arr], "Scan Document - " + (formatDate(new Date()).replaceAll(".", "-")) + ".pdf", { type: "application/pdf" })
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function replaceSpecialKeysInURL(url, keys, values) {
  if (keys.length >= values.length && keys.length > 0) {
    keys.forEach((key, index) => {
      if (url.indexOf(key) != -1) {
        url = url.replaceAll(key, values[index]);
      }
    });
  }

  return url;
}

export function loadingShow() {
  // see documentation for JS Loading Overray library
  // https://js-loading-overlay.muhdfaiz.com
  // @ts-ignore
  JsLoadingOverlay.show({
    overlayBackgroundColor: '#5D5959',
    overlayOpacity: '0.6',
    spinnerIcon: 'ball-atom',
    spinnerColor: '#0AFDDA',
    spinnerSize: '3x',
    overlayIDName: 'overlay',
    spinnerIDName: 'spinner',
    offsetX: 0,
    offsetY: 0,
    containerID: null,
    lockScroll: false,
    overlayZIndex: 9998,
    spinnerZIndex: 9999,
  });
}

export function loadingHide() {
  // @ts-ignore
  JsLoadingOverlay.hide();
}

export function calcScale(zoomLevel, latitude) {
  return Math.round(295034055.1 / (Math.pow(2, zoomLevel) / Math.cos(latitude / 180.0 * Math.PI)));
}

export function calcZoom(scale, latitude) {
  return Math.log(295034055.1 * Math.cos(latitude / 180.0 * Math.PI) / scale) / Math.log(2);
}