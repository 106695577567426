import i18n from "@/i18n";
import { getAddressGoogle, getAddressLocal } from "@/api/map"

export default class Geocode {
  private isGoogleSearch = Number(process.env.VUE_APP_SEARCH_BY_GOOGLE) === 1 ? true : false;
  private originalParent: HTMLElement | null = null;
  private activateGeocoderWithSearchStringCounter: boolean ;
  getApiSource() {
    this.activateGeocoderWithSearchStringCounter = false

    const geocode = {
      forwardGeocode: async (config) => {
        let features = [] as any;
        try {
          if (this.isGoogleSearch){ 
           
            features = await this.callGoogleEngine(config, features);
          }
          else
            features = await this.callLocalEngine(config, features);

        } catch (e) {
          console.error(`${i18n.t('address_not_found')}: ${e}`);
        }
        return {
          features: features,
        };
      },
    };
    return geocode;
  }

  private async callLocalEngine(config, features) {
    const response = await getAddressLocal(config.query);
    const localItems = await response.data;

    features = this.interpretLocalItems(localItems, features);

    return features;
  }
  private moveToTargetContainer() {
   
    const suggestionsList = document.querySelector('.suggestions') as HTMLElement;
    const targetContainer = document.querySelector('#movesuggestionlist') as HTMLElement;

    // Store the original parent if it hasn't been stored already
    if (!this.originalParent && suggestionsList) {
      this.originalParent = suggestionsList.parentElement;
    }

    if (suggestionsList && targetContainer) {
        targetContainer.appendChild(suggestionsList);
        // suggestionsList.style.textAlign = 'left';
        suggestionsList.style.backgroundColor = '#fff ';
        suggestionsList.classList.remove('suggestions'); // Remove the old class
        suggestionsList.classList.add('moved-suggestions');
    }
    this.activateGeocoderWithSearchStringCounter = false
}

private moveToOriginalParent() {
    const suggestionsList = document.querySelector('.moved-suggestions')as HTMLElement;

    if (suggestionsList && this.originalParent) {
      this.originalParent.appendChild(suggestionsList);
        // Remove the left-align style or set it to its original value
        suggestionsList.style.textAlign = ''; 
        suggestionsList.classList.remove('moved-suggestions'); // Remove the old class
        suggestionsList.classList.add('suggestions'); 
    }
}
  private async callGoogleEngine(config, features) {
  
  if(!this.activateGeocoderWithSearchStringCounter) {this.moveToOriginalParent()}else{ this.moveToTargetContainer()}
    const response = await getAddressGoogle(config.query);
    const data = await response.data;
    const geojsonGoogle = JSON.parse(data.google);
    const geojsonHere = JSON.parse(data.hire);

    let googleItems = [];
    if (geojsonGoogle) googleItems = geojsonGoogle;

    let hereItems = [];
    if (geojsonHere) hereItems = geojsonHere.items;

    features = this.interpretGoogleItems(googleItems, features);
    features = this.interpretHereItems(hereItems, features);

    return features;
  }

  private interpretLocalItems(items, features) {
   
    for (const item of items) {
      const center = [item.lon, item.lat];
      const point = {
        type: item.type,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.display_name,
        properties: item,
        text: item.display_name,
        place_type: [item.osm_type],
        center: center,
      } as never;
      features.push(point);
    }
    return features;
  }

  private interpretGoogleItems(items, features) {
    for (const item of items) {
      const center = [item.geometry.location.lng, item.geometry.location.lat];
      const point = {
        type: item.geometry.location_type,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.formatted_address,
        properties: item,
        text: item.formatted_address,
        center: center,
      } as never;
      features.push(point);
    }
    return features;
  }

  private interpretHereItems(items, features) {
    for (const item of items) {
      const center = [item.position.lng, item.position.lat];
      const point = {
        type: item.resultType,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.title,
        properties: item,
        text: item.title,
        center: center,
      } as never;
      features.push(point);
    }
    return features;
  }
activateGeocoderWithSearchString(searchString: string) {
  this.activateGeocoderWithSearchStringCounter =true
    const geocoderInput = document.querySelector('#geocoder-search .mapboxgl-ctrl-geocoder--input') as HTMLInputElement;

    if (geocoderInput) {
      geocoderInput.value = searchString;
      const events = ['keydown', 'keyup'];
     
      for (const event of events) {
          const enterKeyEvent =  new KeyboardEvent(event, {
            'bubbles': true,
            'cancelable': true,
            'key': 'Enter',
            'keyCode': 13, 
            'charCode': 13,
            'which': 13   
        });

          geocoderInput.focus();
          geocoderInput.dispatchEvent(enterKeyEvent);
      }
    }  
   // this.moveToTargetContainer()
   
}

  setSearchGroup(container, map) {
    document.getElementById("geocoder-search")!.appendChild(container.onAdd(map));
    const input = Array.from(
      document.getElementsByClassName("mapboxgl-ctrl-geocoder--input") as HTMLCollectionOf<HTMLElement>
    );
    
    //If search address box is in focus hide search by attribute and search by cadastre
    input[0].addEventListener('focusin', () => {
      const searchAttrInput = document.getElementById("attribute-search");
      searchAttrInput!.style.display = "none";
      const searchCADASTREInput = document.getElementById("cadastre-search");
      searchCADASTREInput!.style.display = "none";
    });
    //If search address box is out if focus show search by attribute and search by cadastre
    input[0].addEventListener('focusout', () => {
      const searchAttrInput = document.getElementById("attribute-search");
      searchAttrInput!.style.display = "block";
      const searchCADASTREInput = document.getElementById("cadastre-search");
      searchCADASTREInput!.style.display = "block";
    });
  }
 
}
