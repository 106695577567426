export default {
  title: "Aquagis",
  baseURL: process.env.VUE_APP_API_BACKEND,
  authURL: 'https://auth.aquagis.dev.sonicomit.com/',
  reportsURL: 'https://reports.aquagis.dev.sonicomit.com/',
  keycloak: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: 'demo-frontend',
    realm: 'master',
    redirectUri: process.env.VUE_APP_BASE_URI,
  },
}