
import { LngLatLike } from "mapbox-gl";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { loadingShow, loadingHide } from "@/utils/index";
import { sendBreakdown } from "@/api/map";
import SidebarBreakdownPopup from "./SidebarBreakdownPopup.vue";
import { ref } from "vue";

@Component({
  components: { SidebarBreakdownPopup },
  computed: {
    ...mapGetters([]),
   
  },
})
export default class SidebarBreakdown extends Vue {
  public toggleIcon: string;
  public data: any;
  public loadingBtn: boolean;
  public itemsType: any;
  public buttonToggle: boolean;
  public error: string;
  public activateAlert: boolean;
  public timer: any;
  public holdTime: any;

  @Watch("activateAlert")
  onChange(data) {
    this.$store.commit("app/SET_ALERT_STATUS", this.activateAlert);
    if (data) {
      document.addEventListener("DOMContentLoaded", function () {
        const element = document.getElementById("activationButton");
        if (element) {
          element.classList.remove("error");
        }
      });
      //this.$emit("toggle-marker");
    }
    else { this.$emit("remove-marker") }
  }

  @Prop() readonly markerCoords: LngLatLike;
  @Prop() readonly selectedElement: any[];

  constructor() {
    super();
    this.toggleIcon = "mdi-pipe-leak";
    this.holdTime = 500;
    this.timer = null;
    this.data = {
      Longitude: "",
      Latitude: "",
      Description: "",
      Address: "",
      Regionname: "",
      Checked: false,
      workCardId: "",
      breakdown_type:"",
    };
  
    this.itemsType = [
        { text: 'Авария', value: '1' },
        { text: 'Планов ремонт', value: '2' }
      ]
    this.buttonToggle = false;
    this.loadingBtn = false;
    this.error = "";
    this.activateAlert = false;
  }
public sendAddress(address) {
    this.$emit('addressSubmitted', address);
  }

public markerForSelection(){
  this.$emit('markerForSelection')
}
  public sendBreakdown(selectedObj) {
    this.loadingBtn = true;
    //this.togglePopup();
    loadingShow();
    this.data.Longitude = this.markerCoords[0];
    this.data.Latitude = this.markerCoords[1];
    this.data.Username = this.$store.getters.username;
    this.data.OsmId = selectedObj.osm_id;
    sendBreakdown(this.data)
      .then((res) => {
        if (res.data.type === 0 || res.data.type === -1) {
          // handler
          this.error = res.data.message;
          this.togglePopup();
        } else {
          this.data.workCardId = res.data.resultData.workCardNo;
          // this.$emit("remove-marker");
          // //this.toggleSidebar();
          this.togglePopup();
        }
      })
      .finally(() => {
        this.loadingBtn = false;
        loadingHide();
        this.$emit("call-refresh-regions-function")
      });
  }

  togglePopup() {
    this.buttonToggle = !this.buttonToggle;
  }
  togglePopupOKBtn() {
    this.buttonToggle = !this.buttonToggle;
    this.toggleSidebar();
  }
  clearForm(){
    this.data = {}
    this.removeMarker()
    this.$emit("clear-selection")
  }

  get getSelectedElement() {
    const element = this.selectedElement;
    if (element.length > 0) {
      return element[0].props;
    }
    return {};
  }

  public startHold() {
    this.timer = setTimeout(() => {
      this.activateAlert = !this.activateAlert;
    }, this.holdTime);
  }
  public endHold() {
    clearTimeout(this.timer);
  }
  public removeMarker(){
    this.$emit("remove-marker")
  }

  public toggleSidebar() {
    if (this.activateAlert) {
      const elem = document.getElementById("sidebar-breakdown");
      const mainToggle = Array.from(
        document.getElementsByClassName(
          "sidebar-main-toggle"
        ) as HTMLCollectionOf<HTMLElement>
      );

      const layersToggle = Array.from(
        document.getElementsByClassName(
          "sidebar-layer-toggle"
        ) as HTMLCollectionOf<HTMLElement>
      );
      
      const breakdownMarkerToggle = Array.from(
        document.getElementsByClassName(
          "sidebar-breakdown-maneger-toggle"
        ) as HTMLCollectionOf<HTMLElement>
      );

      if (elem) {
        const classes = elem.className.split(" ");
        const collapsed = classes.indexOf("collapsed") !== -1;
        if (collapsed) {
          classes.splice(classes.indexOf("collapsed"), 1);

          setTimeout(() => {
            mainToggle[0].style.display = "none";
            breakdownMarkerToggle[0].style.display = "none";
            layersToggle[0].style.display = "none";
            this.toggleIcon = "mdi-arrow-collapse-right";
          }, 100);
        } else {
          classes.push("collapsed");

          setTimeout(() => {
            mainToggle[0].style.display = "flex";
            breakdownMarkerToggle[0].style.display = "flex";
            layersToggle[0].style.display = "flex";
            this.toggleIcon = "mdi-pipe-leak";
          }, 550);
        }
        elem.className = classes.join(" ");
      }
    }
  }
}
