import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './plugins/base'
import vuetify from "./plugins/vuetify";
import i18n from './i18n';
import Snotify from 'vue-snotify';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import config from '@/config'
import 'vue-snotify/styles/material.css';
import 'leaflet/dist/leaflet.css';
import { SVGLoader } from "@/components/";
import axios from "axios";

Vue.use(Snotify);
Vue.config.productionTip = false;
Vue.component('s-v-g-loader', SVGLoader);

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    // Use 'check-sso' to unlock all routes
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  },
  config: {
    //url: 'https://aquagis-mapserver.smartsystems.bg:8081/auth',
    url: config.keycloak.url,
    clientId: config.keycloak.clientId,
    realm: config.keycloak.realm,
  },
  logout: {
    redirectUri: config.keycloak.redirectUri
  },
  onReady: (keycloak) => {
    keycloak.loadUserInfo().then(res => {
      store.dispatch("user/setUsername", res["name"]);
    });

    store.dispatch("user/setToken", keycloak.token);
    setInterval(() => {
      keycloak.updateToken(70) 
        .then(refreshed => {
          // console.log(refreshed)
          if (refreshed) {
            console.log('Token refreshed');
            store.dispatch("user/setToken", keycloak.token);
          } else {
            console.log('Token valid');
          }
        }).catch(err => {
          console.error( err);
        });
    }, 60000);
  }
})

const VueInstance = new Vue({
  store,
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");

export default VueInstance;
