
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SVGLoader } from "@/components/";

// Utilities
import { mapMutations, mapGetters } from "vuex";
@Component({
  components: {
    SVGLoader,
  },
  computed: {
    ...mapGetters(["username", "isMobile", "selected_features","polygonsPointConnectionMode"]),
  },
  methods: {
    ...mapMutations({
      setDrawer: "app/SET_DRAWER",
    }),
  },
})
export default class CoreAppBar extends Vue {
  private profile: object[] = [{ title: "Exit", method: this.signOut }];
  private drawerWidth: number;
  public selectedElementColor: boolean;

  @Watch("selected_features")
  onSelectionChange() {
    this.selectedElementColor = this.$store.getters.selected_features.length
      ? true
      : false;
  }


  constructor() {
    super();
    this.drawerWidth = 0;
    this.selectedElementColor = false;
  }

  mounted() {
    this.$root.$i18n.locale = this.$store.state.app.locale;
    this.drawerWidth = document.getElementById(
      "core-navigation-drawer"
    )!.offsetWidth;
    if (!this.$vuetify.breakpoint.smAndDown) {
      const vue = this;
      setTimeout(() => {
        vue.$store.state.app.drawer = false;
        vue.$forceUpdate();
      }, 0);
    } else {
      const vue = this;
      setTimeout(() => {
        vue.$store.state.app.drawer = false;
        vue.$forceUpdate();
      }, 0);
    }
  }

  get languages() {
    return this.$i18n.availableLocales;
  }

  get currentLanguage() {
    return this.$i18n.locale;
  }

  get drawer() {
    return this.$store.state.app.drawer;
  }

  set drawer(val) {
    this.$store.commit("app/SET_DRAWER", val);
  }

  get getDynamicStyle() {
    return !this.drawer ? "left: 0px" : `left: ${this.drawerWidth}px`;
  }

  @Prop({ required: false, type: Boolean, default: false })
  readonly value: boolean;

  public switchLocale(locale) {
    if (this.$i18n.locale !== locale) {
      this.$root.$i18n.locale = locale;
      this.$store.commit("app/SET_LOCALE", locale);
    }
  }

  public signOut() {
    Vue.prototype.$keycloak.logoutFn();
  }
}
