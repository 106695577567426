import request from '@/utils/request';

export function getValveObjects(){
  return request({
    url: process.env.VUE_APP_API_BACKEND + "Warehouse/GetValveObjects",
    method: 'get',
    params: { projectID: process.env.VUE_APP_PROJECTID }
  }) 
}

export function getMapWMSLayers() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + "WMS/GetWMSLayers",
    method: 'get'
  }) 
}

export function sendBreakdown(data) {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING + 'Regions/CreateWorkCard',
    method: 'post',
    data: data
  })
}
export function disconnectBreakdown(breakdown_id) {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING + 'Regions/disconnectBreakdown',
    method: 'delete',
    data: {breakdownId:breakdown_id}
  })
}
export function getWorkRegions(active) {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING + 'Regions/GetWorkRegions',
    method: 'get',
    params:{ active: active }
  }) 
}

export function getBreakdownsList(id,active) {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING + 'Regions/GetBreakdownsByRegion',
    method: 'get',
    params:{ regionId: id,
      active:active }
  });
}
export function getBreakdownsListTest(query) {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING + 'breakdown/GetBreakdownsList'+query,
    method: 'get',
  });
}
export function getBreakdownsAttributes(breakdown_id) {
  return request({
    url:  process.env.VUE_APP_PROBLEM_REPORTING +'Regions/GetBreakdown',
    method: 'get',
    params:{ breakdownId: breakdown_id }
  });
}
export function updateBreakdown(data) {
  return request({
    url:  process.env.VUE_APP_PROBLEM_REPORTING +'Regions/UpdateBreakdown',
    method: 'put',
    data: data
  });
}
// export function getBreakdownsAttributes() {
//   return request({
//     url: '/api/VisoftReporting/GetBreakdown',
//     method: 'get',
//   });
// }

export function getConfigJsonFormFile() {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING +'Regions/GetFieldDefs',
    method: 'get',
   params:{ projectId: process.env.VUE_APP_PROJECTID }
  });
}

// export function setPolyPointConnection(data) {
//   return request({
//     url: 'http://localhost:3000/config_json',
//     method: 'post',
//     data: data
//   });
// }
export function setPolyPointConnection(data) {
  return request({
    url: process.env.VUE_APP_PROBLEM_REPORTING +'Regions/ConnectPolygon',
    method: 'post',
    data: data
  });
}

export function sendValveState(data) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/ChangeValveState',
    method: 'post',
    data: data,
    params: { projectID: process.env.VUE_APP_PROJECTID }
  })
}

export function loadAllAttributes(ids, src) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/GetAttributes',
    method: 'post',
    data: ids,
    params: { projectID: process.env.VUE_APP_PROJECTID, source: src }
  })
}

export function searchByPropertyValue(data) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SearchByPropertyValue',
    method: 'post',
    params: { projectID: process.env.VUE_APP_PROJECTID },
    data: data
  })
}

export function searchByCadastre(value) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SearchByCadastre',
    method: 'get',
    params: { value: value },
  })
}

export function getAddressGoogle(term) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SearchByAddress',
    method: 'get',
    params: { address: term },
  })
}

export function getAddressLocal(term) {
  return request({
    url: process.env.VUE_APP_ADDRESS_SEARCH,
    method: 'get',
    params: { q: term, limit: 10, format: 'jsonv2' },
  })
}