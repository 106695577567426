
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters([]),
  },
})
export default class SidebarAnalytics extends Vue {
  private toggleIcon: string;

  //@Prop() readonly wsmlayers: object[];

  constructor() {
    super();
    this.toggleIcon = "mdi-select-place";
  }

  public toggleSidebar() {
    const elem = document.getElementById("sidebar-analytics");
    const layersToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-layer-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    const polygonToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-polygon-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );

    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          layersToggle[0].style.display = "none";
          polygonToggle[0].style.display = "none";
          this.toggleIcon = "mdi-arrow-collapse-right";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          layersToggle[0].style.display = "flex";
          polygonToggle[0].style.display = "flex";
          this.toggleIcon = "mdi-select-place";
        }, 550);
      }
      elem.className = classes.join(" ");
    }
  }
}
