
import { LngLatLike } from "mapbox-gl";
import { readonly } from "vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex"




@Component({
  components: { },
  computed: {
    ...mapGetters([]),
  },
})
export default  class SidebarBreakdownPopup extends Vue{
 
  
  @Prop() readonly workCardId: any;
  @Prop() readonly error: string;
   constructor(){
    super();
   
    
   }

   togglePopup(){
    this.$emit("clear-form-remove-marker")
    this.$emit("toggle-popup")
   }
   copyToClipboard(){
    
        if (navigator.clipboard) {
            navigator.clipboard.writeText(this.workCardId).then(() => {
                console.log("Text copied to clipboard");
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        }
    
   }
}
