
  import { Component, Vue } from 'vue-property-decorator';

  interface ISrcs {
    [key: string]: string;
  }

  @Component
  export default class CoreAppProjectHeaderContent extends Vue {
    
  }
