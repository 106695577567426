
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex"
import { getBreakdownsAttributes, getConfigJsonFormFile, updateBreakdown } from "@/api/map";

import DateTimePicker from "@/components/base/DateTimePicker.vue";



@Component({
  components: { DateTimePicker },
  computed: {
    ...mapGetters([]),
  },
})
export default class BreakdownAttributes extends Vue {

  public localDialog: boolean;
  public formConfig: any | null;
  public formData: any | null;
  public customAttr: any | null;


  @Prop() readonly breakdown: any;
  @Prop({ default: false }) readonly dialog!: boolean;
  @Watch('dialog')
  onDialogPropChanged(value: boolean) {
    this.localDialog = value;
  }

  @Watch('breakdown')
  onBreakdownChanged(breakdown) {
    if (breakdown) {
      getBreakdownsAttributes(breakdown.id).then((data) => {

        // this.customAttr =JSON.parse(data.data.resultData.customAttr)
        // console.log(data.data.resultData.customAttr)
        // console.log(this.customAttr)
        //Object.assign(data.data.resultData, this.customAttr);
        // data.data.resultData = { ...data.data.resultData, ...this.customAttr };
        this.initializeFormDataWithDefaultValues(data.data.resultData);
      })
    }
  }

  constructor() {
    super();
    this.localDialog = this.dialog;
    this.formData = {};
    this.customAttr = {};

    this.formConfig = []
    this.getConfigJsonFormFile()
    // this.formConfig = [

    //   {
    //     "type": "text",
    //     "label": "Град/Село",
    //     "model": "town_name",
    //     "options":[],
    //     "dependentOn": "action",
    //     "test":"tewst",

    //   },
    //   {
    //     "type": "text",
    //     "label": "Адрес",
    //     "model": "address"
    //   },
    //   {
    //     "type": "select",
    //     "label": "Действие",
    //     "model": "activity",
    //     "options": [
    //       {
    //         "value": "Ремонт",
    //         "text": "Ремонт"
    //       },
    //       {
    //         "value": "Подмяна",
    //         "text": "Подмяна"
    //       },
    //       {
    //         "value": "Прекъсване",
    //         "text": "Прекъсване"
    //       },
    //       {
    //         "value": "Монтаж",
    //         "text": "Монтаж"
    //       },
    //       {
    //         "value": "Реконструкция",
    //         "text": "Реконструкция"
    //       },
    //       {
    //         "value": "Профилактика на водопроводна мрежа",
    //         "text": "Профилактика на водопроводна мрежа"
    //       },
    //       {
    //         "value": "Профилактика на електрозахранването",
    //         "text": "Профилактика на електрозахранването"
    //       },
    //       {
    //         "value": "Изкопни дейности",
    //         "text": "Изкопни дейности"
    //       },
    //       {
    //         "value": "Монтажни дейности",
    //         "text": "Монтажни дейности"
    //       },
    //       {
    //         "value": "Нова водопроводна връзка",
    //         "text": "Нова водопроводна връзка"
    //       },
    //       {
    //         "value": "Нарушено електрозахранване",
    //         "text": "Нарушено електрозахранване"
    //       },
    //       {
    //         "value": "друго",
    //         "text": "друго"
    //       }
    //     ],
    //     "dependentField": "action_to"
    //   },
    //   {
    //     "type": "select",
    //     "label": "На",
    //     "model": "action_to",
    //     "dependentOn": "action",
    //     "options": [
    //       {
    //         "value": "Водомер",
    //         "text": "Водомер"
    //       },
    //       {
    //         "value": "Водомер преди населено място",
    //         "text": "Водомер преди населено място"
    //       },
    //       {
    //         "value": "Водомерен възел",
    //         "text": "Водомерен възел"
    //       },
    //       {
    //         "value": "Водопровод",
    //         "text": "Водопровод"
    //       },
    //       {
    //         "value": "Външен водопровод",
    //         "text": "Външен водопровод"
    //       },
    //       {
    //         "value": "Канал",
    //         "text": "Канал"
    //       },
    //       {
    //         "value": "Магистрален водопровод",
    //         "text": "Магистрален водопровод"
    //       },
    //       {
    //         "value": "Пожарен хидрант",
    //         "text": "Пожарен хидрант"
    //       },
    //       {
    //         "value": "Ревизионна шахта",
    //         "text": "Ревизионна шахта"
    //       },
    //       {
    //         "value": "Сградно водопроводно отклонение",
    //         "text": "Сградно водопроводно отклонение"
    //       },
    //       {
    //         "value": "Сградно канално отклонение",
    //         "text": "Сградно канално отклонение"
    //       },
    //       {
    //         "value": "Спирателен кран",
    //         "text": "Спирателен кран"
    //       },
    //       {
    //         "value": "Трайно необитаем имот",
    //         "text": "Трайно необитаем имот"
    //       },
    //       {
    //         "value": "Тротоарен спирателен кран",
    //         "text": "Тротоарен спирателен кран"
    //       },
    //       {
    //         "value": "Уличен дъждоприемник",
    //         "text": "Уличен дъждоприемник"
    //       }
    //     ],
    //     "additionalOptions": {
    //       "Ремонт": [],
    //       "Подмяна": [],
    //       "Прекъсване": [],
    //       "Монтаж": [],
    //       "Реконструкция": [],
    //       "Профилактика на водопроводна мрежа*": [],
    //       "Профилактика на електрозахранването*": [],
    //       "Изкопни дейности*": [],
    //       "Монтажни дейности*": [],
    //       "Нова водопроводна връзка*": [],
    //       "Нарушено електрозахранване*": [
    //         {
    //           "value": "test",
    //           "text": "test"
    //         },
    //       ],
    //       "Проучване на водопроводната мрежа*": [
    //         {
    //           "value": "test",
    //           "text": "test"
    //         },
    //       ],
    //       "друго": [
    //         {
    //           "value": "test",
    //           "text": "test"
    //         },
    //         {
    //           "value": "ttt",
    //           "text": "tt"
    //         }
    //       ]
    //     }
    //   },
    //   {
    //     "type": "checkbox",
    //     "label": "Оправено",
    //     "model": "status"
    //   },
    //   {
    //     "type": "datetime",
    //     "label": "Начало",
    //     "model": "startDate",
    //     "format": "dd.MM.yyyy HH:mm"
    //   },
    //   {
    //     "type": "datetime",
    //     "label": "Завършено на",
    //     "model": "completion_date",
    //     "format": "dd.MM.yyyy HH:mm"
    //   },
    //   {
    //     "type": "textarea",
    //     "label": "Описание",
    //     "model": "description"
    //   }
    // ]
  
  }

  getConfigJsonFormFile() {
    getConfigJsonFormFile().then((res) => {
      this.formConfig = res.data.resultData
   
    })
  }


  closeDialog() {
    this.$emit('close-dialog');
    this.formData = this.initializeFormData();
  }

  confirm() {
    console.log(this.formData)
    updateBreakdown(this.formData).then((res) => { console.log(res) }).finally(()=>{
      this.$emit("updated-attr")
    this.$emit('close-dialog');
    })
   
  }

  submitForm() {
    console.log('Form data submitted:', this.formData);
  }

  created() {
    this.formData = this.initializeFormData();
  }

  // initializeFormDataWithDefaultValues(defaultValues) {
  //   this.formData =  this.initializeFormData()
  //   Object.keys(defaultValues).forEach(key => {
  //     const field = this.formConfig.find(f => f.model === key);
  //     if (field && defaultValues[field.model] !== undefined) {
  //       this.formData[field.model] = defaultValues[field.model];
  //       this.formData["Id"] = this.breakdown.id;
  //     }
  //   });
  //   console.log("initializeFormDataWithDefaultValues")
  //   console.log(this.formData)
  // }
  initializeFormDataWithDefaultValues(defaultValues) {
  this.formData = this.initializeFormData();
  Object.keys(defaultValues).forEach(key => {
    const field = this.formConfig.find(f => f && f.model === key);
    if (field && defaultValues[key] !== undefined) {
      this.formData[field.model] = defaultValues[key];
    }
  });
  this.formData["Id"] = this.breakdown.id;
}
  initializeFormData() {
    const initFormData = {};
    this.formConfig.forEach((field) => {
      if (field) {
      initFormData[field.model] = null;
      if (field.type === 'select' && field.options && field.options.length > 0) {
        initFormData[field.model] = null;
      }
    }
    });
    initFormData["Id"] = null;

    return initFormData
  }


  getOptions(field) {
    // console.log("Field:", field.model, "Dependent on:", field.dependentOn);
    if (field.dependentOn) {
      // console.log("Current formData:", this.formData);
      const dependentValue = this.formData[field.dependentOn];
      // console.log("Dependent value for", field.dependentOn, ":", dependentValue);
      if (dependentValue && field.additionalOptions && field.additionalOptions[dependentValue]) {
        if (field.additionalOptions[dependentValue].length == 0) { return null }
        return [
          ...(field.defaultOptions || []),
          ...field.additionalOptions[dependentValue]
        ];
      }
    }
    return field.options;
  }

  getFieldComponent(field) {
    const componentMap = {
      'text': 'v-text-field',
      'textarea': 'v-textarea',
      'select': 'v-select',
      'datetime': 'date-time-picker',
      'number': 'v-text-field',
      'checkbox': 'v-checkbox',
    };
    return componentMap[field.type] || 'v-text-field';
  }

}
