
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import Search from "../../components/map/Search.vue";
import SearchByProperty from "../../components/map/SearchByProperty.vue";
import SearchByCadastre from "../../components/map/SearchByCadastre.vue";
import SelectedEntities from "../../components/map/SelectedEntities.vue";
import ZoneAttributes from "../../components/map/ZoneAttributes.vue";

@Component({
  components: {
    Search,
    SearchByProperty,
    SearchByCadastre,
    SelectedEntities,
    ZoneAttributes,
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    ...mapGetters([
      "drawer",
      "isProduction",
      "selected_features",
      "zones_attributes",
      "zones_attributes_edit_mode",
      "isMobile",
    ]),
  },
})
export default class CoreAppDrawer extends Vue {
  public version = process.env.VUE_APP_VERSION;
  public editZoneAttr: boolean;
  public selectionCount: number;
  public zoneCount: number;
  public isproduction: boolean;
  public selectedElementColor: boolean;
  public showSearchByCadastre: boolean;

  constructor() {
    super();
    this.editZoneAttr = false;
    this.selectedElementColor = false;
    this.showSearchByCadastre =
      Number(process.env.VUE_APP_SEARCH_BY_CADASTRE) === 1 ? true : false;
  }

  @Prop({ required: false, type: Boolean, default: false })
  expandOnHover: boolean;
  search: string;
  @Watch("selected_features")
  onSelectionChange() {
    this.selectedElementColor = this.$store.getters.selected_features.length
      ? true
      : false;
  }
  @Watch("$vuetify.breakpoint.smAndDown")
  smAndDownChanges(val: boolean) {
    this.$emit("update:expandOnHover", !val);
  }

  @Watch("selected_features.length")
  onSelectedCountChange(selectionCount: number) {
    this.selectionCount = selectionCount;
    this.setDrawerAndPanels();
  }

  @Watch("zones_attributes.length", { immediate: true, deep: true })
  onZonesCountChange(zonesCount: number) {
    this.zoneCount = zonesCount;
    this.setDrawerAndPanels();
  }

  @Watch("zones_attributes_edit_mode", { immediate: true, deep: true })
  onZoneModeChange(mode: boolean) {
    this.editZoneAttr = mode;
  }

  @Watch("drawer")
  onDrawerToggel(state: boolean) {
    const bottomLeftBtnGroup = document.getElementsByClassName(
      "mapboxgl-ctrl-bottom-left"
    )[0];
    const drawerWidth = document.getElementById(
      "core-navigation-drawer"
    )!.offsetWidth;
    const position = state
      ? `left: ${drawerWidth}px; transition: ease-in 0.1s`
      : "left: 0px; transition: ease-in 0.1s";
    bottomLeftBtnGroup.setAttribute("style", `${position}`);
  }

  goToSearch() {
    const map = document.getElementById("drawer-map-extention");
    map?.setAttribute("style", "display: none;");

    const zones = document.getElementById("drawer-zone-attributes");
    zones?.setAttribute("style", "display: none;");

    const search = document.getElementById("geocoder-search");
    search?.setAttribute("style", "display: block;");

    const attrsearch = document.getElementById("attribute-search");
    attrsearch?.setAttribute("style", "display: block;");

    const cadastresearch = document.getElementById("cadastre-search");
    cadastresearch?.setAttribute("style", "display: block;");

    const version = document.getElementById("version-container");
    version?.setAttribute("style", "display: block;");

    const searchButton = document.getElementById("geocoder-search-button");
    searchButton?.setAttribute("style", "display: none;");

    const selectionButton = document.getElementById(
      "drawer-map-extention-button"
    );
    selectionButton?.setAttribute("style", "display: block;");
  }

  goToSelection() {
    const map = document.getElementById("drawer-map-extention");
    map?.setAttribute("style", "display: block;");

    const zones = document.getElementById("drawer-zone-attributes");
    zones?.setAttribute("style", "display: block;");

    const search = document.getElementById("geocoder-search");
    search?.setAttribute("style", "display: none;");

    const attrsearch = document.getElementById("attribute-search");
    attrsearch?.setAttribute("style", "display: none;");

    const cadastresearch = document.getElementById("cadastre-search");
    cadastresearch?.setAttribute("style", "display: none;");

    const version = document.getElementById("version-container");
    version?.setAttribute("style", "display: none;");

    const searchButton = document.getElementById("geocoder-search-button");
    searchButton?.setAttribute("style", "display: block;");

    const selectionButton = document.getElementById(
      "drawer-map-extention-button"
    );
    selectionButton?.setAttribute("style", "display: none;");
  }

  public setDrawerAndPanels() {
    let hasAny = false;
    if (this.selectionCount > 0 || this.zoneCount > 0) {
      this.goToSelection();
      //  to display the drawer on selection remove coment
      // if ( !this.$store.state.app.isMobile){hasAny = true;}
      hasAny = false;
    } else {
      this.goToSearch();
    }
    this.$store.state.app.drawer = hasAny;
  }

  get drawer() {
    return this.$store.state.app.drawer;
  }

  set drawer(val) {
    this.$store.commit("app/SET_DRAWER", val);
  }
}
