/* eslint-disable */
import { IMapStoreModule } from './interfaces';
import { MutationTree, ActionTree } from "vuex"

class MapState implements IMapStoreModule {
    public last_map_center: null | object;
    public last_map_zoom: null | number;
    public selected_features: null | object[];
    public selected_poly: null | object[];
    public tag_refs: null | object;
    public zones_attributes: null | any;
    public zones_attributes_edit_mode: null | boolean;
    public zone_target_attribute: null | object;
    public search_feature: null | object;
    public search_cadastre: null | object;
    public export_feature: null | object;

    constructor() {
        this.last_map_center = { lat: 42.698334, lng: 23.319941 };
        this.last_map_zoom = null;
        this.selected_features = [];
        this.selected_poly = [];

        this.tag_refs = {};
        this.zones_attributes = [];
        this.search_cadastre = {};
        this.zones_attributes_edit_mode = false;
        this.zone_target_attribute = {};
        this.search_feature = {};
        this.export_feature ={ };
    }
}

const mutations: MutationTree<MapState> = {
    SET_MAP_CENTER: (state, center) => {
        state.last_map_center = center;
    },
    SET_MAP_ZOOM: (state, zoom) => {
        state.last_map_zoom = zoom;
    },
    SET_MAP_SELECTION: (state, data) => {
        state.selected_features = data;
    },
    SET_MAP_SELECTION_POLY: (state, data) => {
        state.selected_poly = data;
    },
    SET_MAP_TAG_REFS: (state, data) => {
        state.tag_refs = data;
    },
    SET_ZONES_ATTRIBUTES: (state, data) => {
        state.zones_attributes = data;
    },
    SET_ZONES_ATTRIBUTES_MODE: (state, flag) => {
        state.zones_attributes_edit_mode = flag;
    },
    SET_ZONE_TARGET_ATTRIBUTE: (state, data) => {
        state.zone_target_attribute = data;
    },
    SET_SEARCH_FEATURE: (state, data) => {
        state.search_feature = data;
    },
    SET_SEARCH_CADASTRE: (state, data) => {
        state.search_cadastre = data;
    },
    SET_EXPORT_FEATURE: (state, data) => {
        state.export_feature = data;
    },
}

const actions: ActionTree<MapState, any> = {
    changeMapCenter({ commit, dispatch }, center) {
        return new Promise(async resolve => {
            commit('SET_MAP_CENTER', center);
        })
    },

    changeMapZoom({ commit, dispatch }, zoom) {
        return new Promise(async resolve => {
            commit('SET_MAP_ZOOM', zoom);
        })
    },

    changeMapSelection({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_MAP_SELECTION', data);
        })
    },
    changeMapSelectionPoly({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_MAP_SELECTION_POLY', data);
        })
    },

    changeMapTagRefs({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_MAP_TAG_REFS', data);
        })
    },

    changeZonesAttributes({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_ZONES_ATTRIBUTES', data);
        })
    },

    changeZonesAttributesMode({ commit, dispatch }, flag) {
        return new Promise(async resolve => {
            commit('SET_ZONES_ATTRIBUTES_MODE', flag);
        })
    },

    changeZoneTargetAttribute({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_ZONE_TARGET_ATTRIBUTE', data);
        })
    },

    changeSearchFeature({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_SEARCH_FEATURE', data);
        })
    },
    changeSearchCADASTRE({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_SEARCH_CADASTRE', data);
        })
    },
    changeExportFeature({ commit, dispatch }, data) {
        return new Promise(async resolve => {
            commit('SET_EXPORT_FEATURE', data);
        })
    },
}


const state = new MapState();

const MapModule = {
    namespaced: true,
    state,
    mutations,
    actions
}

export default MapModule
