
import { loadAllAttributes } from "@/api/map";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters(["selected_features", "tag_refs"]),
  },
})
export default class SelectedEntities extends Vue {
  public panel: number[];
  public innerPanel: number[];
  public container: object;
  public lengthContainer: object;
  public areaContainer: object;

  @Watch("selected_features")
  onSelectionChange(data: any[]) {
    this.populateFeatures(data);
  }

  @Watch("container")
  onContentChange(data: any[]) {
    Object.keys(data).forEach((key, i) => {
      const _id = data[key][0].props.osm_id ? "osm_id" : "id";
      this.getAllAttributes(
        data[key].map((x) => x.props[_id]),
        key
      );
    });
  }

  constructor() {
    super();
    this.panel = [];
    this.innerPanel = [];
    this.container = {};
    this.lengthContainer = {};
    this.areaContainer = {};
  }

  populateFeatures(data: any[]) {
    this.container = {};
    this.lengthContainer = {};
    data.forEach((ftr) => {
      if (ftr.src.includes("point")) {
        ftr.props.latitude = ftr.geom.coordinates[0];
        ftr.props.longitude = ftr.geom.coordinates[1];
      }

      if (!this.container[ftr.src]) {
        this.container[ftr.src] = [];
      }
      this.container[ftr.src].push(ftr);

      if (ftr.props.aq_length) {
        if (!this.lengthContainer[ftr.src]) {
          this.lengthContainer[ftr.src] = 0;
        }
        this.lengthContainer[ftr.src] += Number(ftr.props.aq_length);
      }
      if (ftr.props.aq_area) {
        if (!this.areaContainer[ftr.src]) {
          this.areaContainer[ftr.src] = 0;
        }
        this.areaContainer[ftr.src] += Number(ftr.props.aq_area);
        if (this.lengthContainer[ftr.src]) {
          delete this.lengthContainer[ftr.src];
        }
      }
    });
    if (data.length === 1) {
      this.panel = [0];
      this.innerPanel = [0];
    } else {
      this.panel = [];
      this.innerPanel = [];
    }
    if (data.length !== 0) {
      // this.$store.commit("app/SET_DRAWER", true);
    }
  }

  public getAllAttributes(ids, src) {
    loadAllAttributes(ids, src).then((res) => {
      if (res.data.length === 0) {
      console.log("No data to process");
      return;
    }

      let _id = Object.keys(res.data[0]).find((x) => x === "osm_id") || "";

      if (!_id) {
        Object.keys(res.data[0])
          .filter((x) => x.includes("id") && Number(res.data[0][x]))
          .forEach((x) => {
            _id = x;
            return;
          });
      }
      res.data.forEach((x) => {
        const obj = this.container[src].find(
          (p) => p.props.osm_id === x[_id] || p.props.id === x[_id]
        );
        if (obj) Object.assign(obj.props, x);
      });
      this.$forceUpdate();
    });
  }

  concatIDs(key) {
    return this.container[key]
      .map((x) => x.props.osm_id || x.props.id)
      .join(", ");
  }
}
