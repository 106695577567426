
import { LngLatLike } from "mapbox-gl";
import { readonly } from "vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex"
import { updateBreakdown } from "@/api/map";



@Component({
  components: {},
  computed: {
    ...mapGetters(["breakdownId"]),
  },
})
export default class ChangeBreakdownLocationPopup extends Vue {
  public preview: boolean;
  public panel: number[];
  public updateData: any;
  public error: string | null;


  @Prop() readonly selectedElement: any[];

  @Prop() readonly markerCoords: LngLatLike;


  constructor() {
    super();
    this.preview = false;
    this.panel = [0];
    this.error = null
    this.updateData = {
      longitude: 0,
      latitude: 0,
      osm_id: 0,
      Id: null,
    }

  }

  togglePopup() {

    this.$store.commit("app/SET_BREAKDOWN_COORDS_MODE", false);
    this.$emit("close-location-panel");

  }
  displayError() {
    this.error = "Моля Изберете локация";
    setTimeout(() => {
      this.error = null;
    }, 1500);
  }


  confirmNewLocation() {
    this.updateData.longitude = this.markerCoords[0]
    this.updateData.latitude = this.markerCoords[1]
    if (this.updateData.longitude == 0 || this.updateData.latitude == 0) {
      this.displayError()
    }
    if (this.selectedElement[0].props.osm_id) {
      this.updateData.osm_id = this.selectedElement[0].props.osm_id
    }
    else { this.displayError() }

    this.updateData.Id = this.$store.state.app.breakdownId

    updateBreakdown(this.updateData).then((res) => {
      console.log(res)
    }).finally(()=>{ this.togglePopup()})

   
   
  }

}
