
  import { Component, Vue } from 'vue-property-decorator';
  import CoreAppFooter from "./Footer.vue";
  import CoreAppProjectHeaderContent from "./ProjectHeaderContent.vue";

  interface ISrcs {
    [key: string]: string;
  }

  @Component({
    components: {
      CoreAppFooter,
      CoreAppProjectHeaderContent
    }
  })
  export default class CoreAppView extends Vue {
    private srcs: ISrcs = {
      '/pages/lock': 'lock.jpg',
      '/pages/login': 'login.jpg',
    }


    get src(): string {
      return this.srcs[this.$route.path];
    }

    get styles () {
      // const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 25 : 40
      // const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 25 : 40
      return '';
      // return {
      //   padding: `${paddingTop}px 20px ${paddingBottom}px 20px`,
      // }
    }
  }
