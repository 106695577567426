
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import DateTimePicker from "@/components/base/DateTimePicker.vue";
import layer from "@/utils/constants/map/layer";

@Component({
  components: {
    DateTimePicker,
  },
  computed: {
    ...mapGetters(["zones_attributes"]),
  },
})
export default class ZoneAttributes extends Vue {
  public panel: number[];
  public zoneType: object[];
  public objectTypes: any;
  public lineType: any;
  public layers: any;
  public polygonAttr: any;

  @Watch("zones_attributes")
  onZoneChange(data: any) {
    this.polygonAttr = data;
  }

  constructor() {
    super();
    this.panel = [];
    this.layers = layer;
    this.polygonAttr = [];
    this.zoneType = [
      { key: 0, value: this.$t("area_with_disrupted_water_supply") },
      { key: 1, value: this.$t("section_under_construction") },
      { key: 2, value: this.$t("completed_section") },
    ];
    this.objectTypes = {
      [layer.ZONES_POLYGON]: this.$t("zone_polygon"),
      [layer.ZONES_LINE]: this.$t("zone_line"),
      [layer.ZONES_POINT]: this.$t("zone_point"),
    };
    this.lineType = [
      { key: 0, value: this.$t("water") },
      //{ key: 1, value: this.$t("sewer") },
    ];
  }

  public onAttrChange(id, prop) {
    const target = {
      osm_id: id,
      key_value: prop,
    };

    this.$store.dispatch("map/changeZoneTargetAttribute", target);
  }
}
