const WATERLINES = 'waterlines';
const WATERPOINTS = 'waterpoints';
const VALVEPOINTS = 'valve_waterpoints';
const SELECT_WATERLINE = 'select-line-water';
const SELECT_WATERPOINT = 'select-point-water';
const SELECT_VALVEPOINT = 'select-valve-waterpoints';
const ZONES_POLYGON = "zones_poly";
const ZONES_LINE = "zones_line";
const ZONES_POINT = "zones_point";
const WATERLINES_LABEL = "waterlines-label";
const WATERPOINTS_LABEL = "waterpoints-label";
const VALVEPOINTS_LABEL = "valve_waterpoints-label";
const MEASURE_POINTS = "measure-points";
const MEASURE_LINES = "measure-lines";
const AREAL_POLY_WATER = "areal_poly_water";
const AREAL_LINE_WATER = "areal_poly_water-line";
const SELECT_AREAL_WATER = "select-areal-water";
const AREAL_POLY_WATER_LABEL = "areal_poly_water-label";
const SELECT_ZONES_POLY = "select-zone-poly";
const SELECT_ZONES_LINE = "select-zone-line";
const SELECT_ZONES_POINT = "select-zone-point";
const ZONES_POLYGON_LABEL = "zone_poly-label";
const ZONES_LINE_LABEL = "zone_line-label";
const ZONES_POINT_LABEL = "zone_point-label";
const ANALIZELINES = "analizelines";
const ANALIZEPOINTS = "analizepoints";
const WATERMETERS_RESIDENTIAL = "watermeters_residential";
const SELECT_WATERMETERS_RESIDENTIAL = "select-watermeters_residential";
const WATERMETERS_RESIDENTIAL_LABEL = "watermeters_residential-label";
const WATERMETERS_PUBLIC = "watermeters_public";
const SELECT_WATERMETERS_PUBLIC = "select-watermeters_public";
const WATERMETERS_PUBLIC_LABEL = "watermeters_public-label";
const BREAKDOWN_POINTS = "breakdownpoints";
const SELECT_BREAKDOWN_POINTS = "select-breakdownpoints";
const BREAKDOWN_POINTS_LABLE = "breakdownpoints-label";
const ZONES_POLY_BREAKDOWN = "zones_poly_breakdown";
const SELECT_ZONE_POLY_BREAKDOWN = "select-zone-poly_breakdown";
const ZONE_POLY_LABLE_BREAKDOWN = "zone_poly-label_breakdown";



const MAP_INIT_LAYER = {
    container: "map",
    style: "mapbox://styles/aquagis/cljwpwo6j024301pjefif51ln",
    center: JSON.parse(process.env.VUE_APP_OSM_CENTER),
    boxZoom: false,
    zoom: 9,
    minZoom: 3,
    maxZoom: 22,
    preserveDrawingBuffer: true,
} as any

export default {
    WATERLINES,
    WATERPOINTS,
    MAP_INIT_LAYER,
    SELECT_WATERLINE,
    SELECT_WATERPOINT,
    ZONES_POLYGON,
    ZONES_LINE,
    ZONES_POINT,
    WATERLINES_LABEL,
    WATERPOINTS_LABEL,
    MEASURE_POINTS,
    MEASURE_LINES,
    AREAL_POLY_WATER,
    AREAL_LINE_WATER,
    AREAL_POLY_WATER_LABEL,
    SELECT_AREAL_WATER,
    SELECT_ZONES_LINE,
    SELECT_ZONES_POINT,
    SELECT_ZONES_POLY,
    ZONES_POLYGON_LABEL,
    ZONES_LINE_LABEL,
    ZONES_POINT_LABEL,
    ANALIZELINES,
    ANALIZEPOINTS,
    WATERMETERS_RESIDENTIAL,
    SELECT_WATERMETERS_RESIDENTIAL,
    WATERMETERS_RESIDENTIAL_LABEL,
    WATERMETERS_PUBLIC,
    SELECT_WATERMETERS_PUBLIC,
    WATERMETERS_PUBLIC_LABEL,
    VALVEPOINTS,
    VALVEPOINTS_LABEL,
    SELECT_VALVEPOINT,
    BREAKDOWN_POINTS_LABLE,
    SELECT_BREAKDOWN_POINTS,
    BREAKDOWN_POINTS,
    ZONE_POLY_LABLE_BREAKDOWN,
    SELECT_ZONE_POLY_BREAKDOWN,
    ZONES_POLY_BREAKDOWN
}