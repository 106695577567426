
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { sendValveState } from "@/api/map";
import { loadingShow } from "@/utils";

@Component({
  components: {},
  computed: {
    ...mapGetters([]),
  },
})
export default class MapBoxCustomPopup extends Vue {
  public explStatus: any;
  public valveStateModel: any;

  @Prop() readonly selectedElement: any;
  @Prop() readonly operationals: any;
  @Prop() readonly conditions: any;
  @Prop() readonly positions: any;

  constructor() {
    super();
    this.explStatus = [];
    this.valveStateModel = {
      valve_id: null,
      position_id: null,
      condition_id: null,
      operational_id: null,
      percent_open: null,
      note: "",
    };
  }

  get getElement() {
    const element = this.selectedElement;

    if (element[0].props.valve_state_json) {
      this.valveStateModel = JSON.parse(element[0].props.valve_state_json);
    }

    if (element.length > 0) {
      return element[0].props;
    }

    return {};
  }
  public sendInformation() {
    loadingShow();
    this.valveStateModel.valve_id = this.selectedElement[0].props.osm_id;
    sendValveState(this.valveStateModel).then((res) => {
      this.$emit('valve-state-saved', res.data);
    });
  }
}
