
import { readonly } from "vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex"
import { setPolyPointConnection,} from "@/api/map";




@Component({
  components: {},
  computed: {
    ...mapGetters(["selected_poly",]),
  },
})
export default class ConfirmPolyPointConnection extends Vue {

  public localDialog: boolean;
  public data: any;
  public selected_poly: any;
  // public breakdownID: any | null ;


  @Prop() readonly breakdown: any;
  @Prop({ default: false }) readonly dialog!: boolean;
  @Watch('dialog')
  onDialogPropChanged(value: boolean) {

    this.localDialog = value;

  }

  // @Watch("breakdown")
  // onBreakdownChange(breakdown: any) {
  //   this.breakdownID = breakdown.id
  // }
  @Watch("selected_poly")
  onSelectionChange(data: any[]) {
    // this.selected_poly(data);
  }


  constructor() {
    super();
    this.localDialog = this.dialog;
    this.data = {
      "polygonId": null,
      "breakdownId": null
    }
    // this.breakdownID = null

  }

  closeDialog() {
    this.localDialog = false;
    this.$emit('close-dialog-poly');
  }
  confirm() {
    this.data.polygonId =  this.selected_poly[0].props.osm_id
    this.data.breakdownId = this.breakdown.id
    setPolyPointConnection(this.data).then((res) => {
        console.log(res)
      }).finally(()=>{ console.log(this.data)
    this.localDialog = false;
    this.$emit('close-dialog-poly');})
    
  
   
  }

}
