import { LocaleMessageObject } from "vue-i18n";

const en: LocaleMessageObject = {
  avatar: "Boril Kolev",
  buttons: "Buttons",
  calendar: "Calendar",
  charts: "Charts",
  components: "Components",
  dashboard: "Dashboard",
  dtables: "Data Tables",
  eforms: "Extended Forms",
  error: "Error Page",
  etables: "Extended Tables",
  example: "Example",
  forms: "Forms",
  fullscreen: "Full Screen Map",
  google: "Google Maps",
  grid: "Grid System",
  icons: "Icons",
  lock: "Lock Screen Page",
  login: "Login Page",
  maps: "Maps",
  multi: "Multi Level Collapse",
  notifications: "Notifications",
  pages: "Pages",
  "my-profile": "My Profile",
  "edit-profile": "Edit Profile",
  search: "Search",
  settings: "Settings",
  tables: "Tables",
  tabs: "Tabs",
  user: "User Profile",
  gis: "Aquagis",
  gis_short: "G",
  "sign-in": "Sign In",
  username: "Username",
  password: "Password",
  "add-point": "Add point",
  "point-details": "Waste point details",
  description: "Description",
  save: "Save",
  latitude: "Latitude",
  longitude: "Longitude",
  coordinates: "Coordinates",
  "registered-on": "Registered on",
  close: "Close",
  "table-data-of-waste-points": "Table view of waste points",
  created: "Creation date",
  remarks: "Remarks",
  users: "Users",
  "full-name": "Name",
  phone: "Phone",
  email: "E-mail",
  "add-user": "Create user",
  "user-details": "User details",
  "first-name": "First name",
  "middle-name": "Middle name",
  "last-name": "Last name",
  roles: "Roles",

  /* API MESSAGES */
  "Incorrect-username-password": "Wrong username or password",
  "NOT-AUTHORIZED": "You are not authorized. Please 'sign in'.",

  /* MENU */
  "valves": "Valves",

  /* TABLES */
  "$vuetify.noDataText": "No data available",
  "$vuetify.dataFooter.itemsPerPageText": "Items per page:",
  "$vuetify.dataFooter.itemsPerPageAll": "All",
  "$vuetify.dataFooter.nextPage": "Next page",
  "$vuetify.dataFooter.prevPage": "Previous page",
  "$vuetify.dataFooter.firstPage": "First page",
  "$vuetify.dataFooter.lastPage": "Last page",
  "$vuetify.dataFooter.pageText": "{0}-{1} of {2}",
  "$vuetify.dataTable.itemsPerPageText": "Rows per page:",
  "$vuetify.dataTable.ariaLabel.sortDescending": "Sorted descending.",
  "$vuetify.dataTable.ariaLabel.sortAscending": "Sorted ascending.",
  "$vuetify.dataTable.ariaLabel.sortNone": "Not sorted.",
  "$vuetify.dataTable.ariaLabel.activateNone": "Activate to remove sorting.",
  "$vuetify.dataTable.ariaLabel.activateDescending":
    "Activate to sort descending.",
  "$vuetify.dataTable.ariaLabel.activateAscending":
    "Activate to sort ascending.",
  "$vuetify.pagination.ariaLabel.previous": "Previous page",
  "$vuetify.pagination.ariaLabel.next": "Next page",
  "$vuetify.pagination.ariaLabel.page": "Page",
  "$vuetify.pagination.ariaLabel.currentPage": "Current page",
  "$vuetify.dataIterator.loadingText": "Please wait...",
  "$vuetify.pagination.ariaLabel.wrapper": "Pagination Navigation",
  "$vuetify.dataIterator.noResultsText": "No results",
  "$vuetify.dataTable.sortBy": "Sort by",

  /* Project content */
  project_header_content:
    "Project CB005.2.12.075: “Revitalization of abandoned dump sites”",
  project_footer_content: `The project is co-funded by the European Union through the Interreg-IPA CBC Bulgaria-Turkey Programme.<br />
  This website was created and maintained with the assistance of the European Union through the Interreg-IPA CBC Bulgaria -Turkey Programme, CCI No 2014TC16I5CB005. The contents of this website are the sole responsibility of Center for Education and scientific cooperation “Maxima” and can in no way be taken to reflect the views of the European Union or the Managing Authority and the National Authority of the Programme.`,
  system_and_objects: "System and objects",
  external_layers: "External",
  others: "Others",
  hide_all_symbols: "Hide all Symbols",
  show_all_symbols: "Show all Symbols",
  clear_selection: "Clear selection",
  filters: "Filters",
  colors: "Colors",
  select_attr_for_thematic_coloring: "Select attribute for thematic coloring",
  object_labels: "Object labels",
  attribute: "Attribute",
  line_thicknesses_by_diameter: "Line thicknesses by diameter",
  specify_from: "Specify from",
  to: "To",
  thickness: "Thickness",
  pixels: "Pixels",
  wms_bkgr: "WMS Backgroung",
  main_map: "Main map",
  system: "System",
  styles: "Styles",
  layers: "Layers",
  prepared_by: "Prepared by",
  contractor: "Contractor",
  data: "Data",
  object: "Object",
  scale: "Scale",
  cancel: "Cancel",
  full_network: "Full network",
  export: "Export",
  selection: "Selection",
  version: "Version",
  clear_all_filters: "Clear all filters",
  select_or_calcel_group: "Select/Cancel group",
  show_from: "Show from",
  invalid_number: "Invalid Number",
  reset_settings: "Reset settings",
  go_to_id: "Go To iD",
  go_to_search: "Go To Search",
  go_to_selection: "Go To Selection",
  go_to_superset: "Go To Superset",
  select_one_type_of_objects:
    "Please select only one type of object - linear or nodes.",
  attributes_of: "Attributes of",
  name: "Name",
  no_results_found: "No results found",
  type: "Type",
  creation_date: "Creation date",
  start_date: "Start time of interruption",
  end_time_expected: "Planned recovery",
  end_time_real: "Actual recovery",
  last_edit: "Last correction of zone",
  created_by: "Created by",
  active_zone:"Active",
  inactive_zone:"Inactive",
  zone_polygon: "Polygon",
  section_under_construction: "Section under construction",
  completed_section: "Completed section",
  area_with_disrupted_water_supply: "Area with disrupted water supply",
  zone_line: "Line",
  zone_point: "Point",
  water: "Water",
  sewer: "Sewer",
  measuring: "Measuring",
  line: "Line",
  delete: "Delete",
  drawing: "Drawing",
  attributes: "Attributes",
  cancel_changes: "Cancel changes",
  cancel_measuring: "Cancel measuring",
  legend: "Legend",
  inc_number: "Inc. No",
  data_exit_data: "Data / Output data",
  date: "Date",
  linear: "Linear",
  points: "Points",
  areal_objects: "Areal",
  electric_network: "Electric network",
  gas_distribution_network: "Gas distribution network",
  communication_network: "Comпunication network",
  loggers: "Loggers",
  detailed_points: "Detailed points",
  signal_cables: "Signal cables",
  power_cables: "Power cables",
  fence: "Fence",
  other_lines: "Other lines",
  other_points: "Other points",
  developer_tools: "Additional tools",
  for_specific_scale: "For specific scale, enter new value (1:500 for example)",
  total_length: "Total length: ",
  unit_of_measure_km: "km",
  unit_of_measure_m: "meters",
  filter_by: "Filter by",
  map_center: "Center",
  map_scale: "Scale",
  show_all_zones: "Show all project objects",
  hide_all_zones: "Hide all project objects",
  analize: "Network analysis",
  cancel_analize: "Exit mode 'Network analysis'",
  search_by_attr: "Search by attributes",
  water_meters_residential: "Residential clients",
  water_meters_public: "Municipal clients",
  unit_of_measure_m2: "sqm",
  breakdown_title:"Accidents",
  search_value: "Value",
  search_cadastre: "Search by property",
  coordinate_system: "Coordinate system",
  change_breakdown_coordinates: "Change of coordinates",
  connect_breakdown_to_polygon: "Connecting an breakdown with a polygon",
  edit_breakdown_attributes: "Changing attributes",
  go_to_breakdown_location: "Show breakdown",
  confirm:"Confirm",
  material:"material:",
  diameter:"diameter",
  move_to_address: "Move to Address",
remove_marker: "Remove Marker",
place_marker: "Place Marker",
send: "Send",
disconnect_breakdown_from_polygon:"Remove all breakdown polygon connections"

};

export default en;
