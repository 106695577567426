import { IRequestData } from '@/interfaces/Request';
import request from '@/utils/request';
import axios, { AxiosPromise } from 'axios';


export function getMapSettings() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/GetMapDefinition',
    method: 'get',
    params: {
      projectID: process.env.VUE_APP_PROJECTID
    }
  }) as AxiosPromise<IRequestData>
}

export function getConditionalFilters() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/GetConditionalFilters',
    method: 'get'
  }) as AxiosPromise<IRequestData>
}