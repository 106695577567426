
/* eslint-disable */
import { MutationTree, ActionTree } from "vuex";
import { IAppStoreModule } from './interfaces';

class AppState implements IAppStoreModule {
  public loading: boolean;
  public drawer: boolean;
  public locale: string;
  public isMobile: null | boolean;
  public breakdownId: number |null;
  public predefinedBreakdownLayers: null | boolean;
  public polygonsPointConnectionMode: null |boolean;
  public breakdownCoordinatesChangeMode: null |boolean;

  public alertStatus: null | boolean;
  constructor() {
    this.loading = false;
    this.drawer = false;
    this.alertStatus = false;
    this.isMobile  = null;
    this.breakdownId = null;
    this.predefinedBreakdownLayers = false;
    this.polygonsPointConnectionMode = false;
    this.breakdownCoordinatesChangeMode =false;
    this.locale = "en";
  }
}

const mutations: MutationTree<AppState> = {
  TOGGLE_LOADING: state => {
    state.loading = !state.loading;
  },
  STOP_LOADING: state => {
    state.loading = false;
  },
  START_LOADING: state => {
    state.loading = true;
  },
  SET_DRAWER: (state, payload) => {
    state.drawer = payload;
  },
  SET_LOCALE: (state, locale) => {
    state.locale = locale
  }, 
  SET_MOBLIE: (state, payload) => {
    state.isMobile = payload
  },
  SET_BREAKDOWN_ID: (state, payload) => {
    state.breakdownId = payload
  },
  SET_PREDEFINED_BREAKDOWN_LAYERS: (state, payload) => {
    state.predefinedBreakdownLayers = payload
  },
  SET_CONNECTION_POLY_POINT_MODE:(state, payload) => {
    state.polygonsPointConnectionMode = payload
  },
  SET_BREAKDOWN_COORDS_MODE:(state, payload) => {
    state.breakdownCoordinatesChangeMode = payload
  },
  SET_ALERT_STATUS: (state, payload) => {
    state.alertStatus = payload
  }
}

const actions: ActionTree<AppState, any> = {
  toggleLoading({ commit }) {
    commit('TOGGLE_LOADING')
  },
  stopLoading({ commit }){
      commit('STOP_LOADING');
  },
  startLoading({ commit }){
      commit('START_LOADING');
  }
  
}

const state = new AppState();

const AppModule = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default AppModule;